// Contact Form 7

.wpcf7-form{
	p {
		margin: 0 0 0 0 ;
	}

  &-control-wrap {
    padding: $inuit-global-spacing-unit 0;
  }
}

.wpcf7-not-valid-tip {
  font-size: $inuit-font-size-h6 !important;
  line-height: 1.3;
  font-weight: 500 !important;
  color: color-var(error);
	position: absolute;
  right: 0;
  top: -$inuit-global-spacing-unit;

  &::before {
    content: '!';
    position: absolute;
    left: -$inuit-global-spacing-unit-large;
    text-align: center;
    top: 0;
    width: $inuit-global-spacing-unit;
    height: $inuit-global-spacing-unit;
    color: white;
    background-color: color-var(error);
    border-radius: 100%;
  }
}