/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-button,
a.c-button {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: $inuit-global-spacing-unit-micro 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: ($inuit-global-spacing-unit-small - 2px) $inuit-global-spacing-unit-great;
  transition: $global-transition;
  @include inuit-font-size($inuit-font-size-h5, 1.136em);
  text-decoration: none;
  border: $global-border solid color-var(border);
  border-radius: $global-radius;
  color: color-var(font, 3);
  position: relative;

  &:before {
    @include absolutePseudoContent();
    @include absPosition100();
    background: color-var(bg, 8);
    opacity: 0;
    @include transition();
    pointer-events: none;
  }

  .c-label--file:hover &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    // color: inherit;

    &:before {
      opacity: 0.2;
    }
  }

  &>* {
    pointer-events: none;
  }

  /* Style variants
   ========================================================================== */

  &--primary {
    border-color: color-var(border, 2);
    background-color: color-var(bg);
    color: color-var(font, 4);

    .c-icon * {
      fill: color-var(font, 4);
    }

    &.c-button--alternative {
      border-color: color-var(border, 4);
      color: color-var(font, 3);

      .c-icon * {
        fill: color-var(font, 3);
      }
    }
  }

  &--secondary {
    border-color: color-var(border, 2);
    background-color: color-var(bg, 2);

    .c-icon * {
      fill: color-var(font, 3);
    }

    &.c-button--alternative {
      border-color: color-var(border, 4);
      color: color-var(font, 4);
      background-color: color-var(bg, 1);

      .c-icon * {
        fill: color-var(font, 4);
      }

      &::before {
        border-radius: $global-radius;
      }
    }
  }

  &--tertiary {
    border-color: color-var(border, 5);
    color: color-var(font);

    .c-icon * {
      fill: color-var(font);
    }

    &.c-button--alternative {
      border-color: color-var(border);
      color: color-var(font, 4);
      padding: $inuit-global-spacing-unit-small - 1;

      .c-icon * {
        fill: color-var(font, 4);
      }
    }

    &.is-active {
      border-color: color-var(border, 3);
      background-color: color-var(bg, 3);
      color: color-var(font, 3);
    }
  }

  &--quaternary {
    border-color: color-var(border, 7);
    background: color-var(font, 1);
    color: color-var(font, 3);

    .c-icon * {
      fill: color-var(font, 3);
    }

    &.c-button--alternative {
      border-color: color-var(font, 3);
      color: color-var(font, 1);
      background-color: color-var(font, 3);
      border: none;

      &::before {
        border-radius: $global-radius;
      }

      .c-icon * {
        fill: color-var(font, 1);
      }
    }

    &.is-active {
      border-color: color-var(border, 3);
      background-color: color-var(bg, 3);
      color: color-var(font, 3);
    }
  }

  &--square {
    padding: ($inuit-global-spacing-unit-small - 2px) 2px;
    min-width: $inuit-global-spacing-unit-huge;
    margin: 0 $inuit-global-spacing-unit-micro;
  }

  &--invisible {
    opacity: 0;
    pointer-events: none;
  }

  &--disabled {
    pointer-events: none;

    &-colored {
      color: color-var(font, 2);
      border-color: color-var(border, 5);
    }
  }

  &--error {
    border-color: color-var(error);
    background-color: color-var(error);

    .c-icon * {
      fill: color-var(font, 3);
    }

    &.c-button--alternative {
      color: color-var(error);
      background-color: transparent;

      .c-icon * {
        fill: color-var(error);
      }
    }
  }

  &--success {
    border-color: color-var(success);
    background-color: color-var(success);

    .c-icon * {
      fill: color-var(font, 3);
    }

    &.c-button--alternative {
      color: color-var(success);
      background-color: transparent;

      .c-icon * {
        fill: color-var(success);
      }
    }
  }

  &--full {
    width: 100%;
  }

  &--full\@mobile {
    @include bp-down(medium) {
      width: 100%;
    }
  }

  &--fixed {
    min-width: $inuit-global-baseline * 50;
  }

  &--flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  &--with-arrow{    
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    svg *{
      stroke-width: 1;
    }
  }
  // &--alternative {
  //   background-color: color-var(bg, 1);
  //   color: color-var(font);

  //   &:before {
  //     display: none;
  //   }

  //   &:hover,
  //   &:active,
  //   &:focus {
  //     color: color-var(font, 3);

  //     &.c-button--primary {
  //       color: color-var(bg, 2);

  //       .c-icon * {
  //         fill: color-var(bg, 2);
  //       }
  //     }

  //     &.c-button--secondary {
  //       color: color-var(bg, 3);

  //       .c-icon * {
  //         fill: color-var(bg, 3);
  //       }
  //     }

  //     &.c-button--tertiary {
  //       color: color-var(bg, 4);

  //       .c-icon * {
  //         fill: color-var(bg, 4);
  //       }
  //     }

  //     &.c-button--error {
  //       color: color-var(error);

  //       .c-icon * {
  //         fill: color-var(error);
  //       }
  //     }

  //     &.c-button--success {
  //       color: color-var(success);

  //       .c-icon * {
  //         fill: color-var(success);
  //       }
  //     }
  //   }
  // }

  &--with-icon {
    position: relative;
    padding-left: $inuit-global-spacing-unit-huge;
    padding-right: $inuit-global-spacing-unit-big;

    &-right {
      padding-left: $inuit-global-spacing-unit-big;
      padding-right: $inuit-global-spacing-unit-huge;

      .c-icon {
        left: auto;
        right: $inuit-global-spacing-unit-tiny;
      }
    }

    // &.c-button--alternative {
    //   .c-icon * {
    //     fill: color-var(font, 3);
    //   }
    // }
  }

  /* Size variants
   ========================================================================== */

  &--small {
    padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;

    &.c-button--with-icon {
      padding-left: $inuit-global-spacing-unit-great;

      &-right {
        padding-left: $inuit-global-spacing-unit-small;
        padding-right: $inuit-global-spacing-unit-great;
      }
    }
  }

  &--smaller {
    padding: ($inuit-global-spacing-unit-small - 2px) $inuit-global-spacing-unit;
  }

  &--smallest {
    padding: 5px 6px;
    font-size: $inuit-global-spacing-unit-small - 1px;
    border-radius: 2px;
    line-height: 1em;
    white-space: nowrap;
    margin-right: 4px;

    &.c-button--primary {
      border-color: color-var(border, 1);
    }
  }

  &--large {
    padding: 0.75rem 1.5rem;
  }

  &--higher {
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-large;
    font-weight: 500;
  }

  &--search {
    padding-left: $inuit-global-spacing-unit-huge * 1.35;
    padding-right: $inuit-global-spacing-unit-large;

    @include mq($until: 'medium') {
      font-size: 0;
      padding: $inuit-global-spacing-unit;
      width: 50px;
      min-width: 50px !important;
    }

    .c-icon {
      left: $inuit-global-spacing-unit-large;

      @include mq($until: 'medium') {
        position: static;
      }
    }
  }

  &.is-loading {
    pointer-events: none;
    background: color-var(success);
    border-color: color-var(success);
    padding-right: 50px;

    &:after {
      content: '.';
      width: 10px;
      height: 10px;
      border-radius: 10px;
      box-shadow: 0 0 0 2px #fff;
      position: absolute;
      top: 0;
      font-size: 24px;
      line-height: 4px;
      font-weight: 700;
      right: 15px;
      margin: auto;
      bottom: 0;
      animation: spin 2s linear infinite;
    }
  }
}

.c-buttons {
  margin-bottom: $inuit-global-spacing-unit-great;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.js-filter-counter:not(:empty){
  min-width: 16px;
    min-height: 16px;
    padding: 2px 4px;
    line-height: 12px;
    border-radius: 30px;
    background: #0063C1;
    color: #fff;
    font-size: 12px;
    display: inline-flex;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
}

.c-header__search .c-button{
  display: flex;
  justify-content: center;
  align-items: center;
  .c-icon {
    height: 24px;
    svg *{
      fill:none;
      stroke:#fff!important;
    }
  }
}