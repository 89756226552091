.c-post-tease {
  $this: &;
  position: relative;
  border: 1px #858585 solid;
  border-radius: $global-radius;
  padding: $inuit-global-spacing-unit-great;
  margin-bottom: $inuit-global-spacing-unit-great;
  @include inuit-font-size($inuit-font-size-h5, 1.8em);

  @include mq($from: large) {
    flex-wrap: nowrap;
  }

  &__img {
    padding-right: $inuit-global-spacing-unit-great;

    img {
      margin-bottom: 0;
      min-width: 150px;
      @include mq($until: medium) {
        margin-bottom: $inuit-global-spacing-unit-great;
      }
    }
  }

  &__title {
    @include inuit-font-size($inuit-font-size-h4);
    font-weight: 700;
    margin-bottom: $inuit-global-spacing-unit-small !important;
  }

  &__list {
    margin-bottom: 0;
  }

  &__socials {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: $inuit-global-spacing-unit-small;

    & > a {
      padding: 0 !important ;

      &::after {
        display: none;
      }
    }
  }

  .c-button {
    margin-top: $inuit-global-spacing-unit-large;
  }

  &--summary {
    border-color: color-var(border, 2);

    #{$this}__logo {
      @include mq($until: large) {
        position: static;
        max-height: 140px;
      }
      position: absolute;
      top: $inuit-global-spacing-unit-great;
      right: $inuit-global-spacing-unit-great;
      bottom: 0;
      margin: 0;
      max-height: 70px;
      height: auto;
      width: auto;
    }
  }
}
