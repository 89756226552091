.c-cookies {
  max-width: 100%;
  display: none;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 2rem;
  z-index: 2000;

  &.is-active {
    display: block;
    opacity: 1;
    @include fade-in;
  }

  &__container {
    background-color: var(--color-bg-1);
    border-radius: $global-radius;
    @include mq($from: large) {
      margin: 0 $inuit-global-spacing-unit-massive;
    }
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-great;
    @include mq($until: large) {
      display: block;
    }
  }

  &__description {
    margin-bottom: 0;
    padding-right: $inuit-global-spacing-unit-big;
  }
}
