.c-popup {
  $this: &;

  width: 100%;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: color-var(bg, 1);
  border-radius: $global-radius;
  padding: 20px !important;
  @include mq($from: large) {
    padding: 64px 100px 0 !important;
  }

  .c-label {
    &__container {
      width: 100% !important;
      margin-bottom: 0;
    }

    &--toggleable {
      border: none !important;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 64px;
  }

  &__content {
    width: 100%;
    height: 100%;

    .c-checkbox {
      line-height: 1 !important;

      &:not(.c-label--toggleable) {
        &:not(:last-of-type) {
          margin-bottom: $inuit-global-spacing-unit-small !important;
        }

        &:first-of-type {
          margin-top: $inuit-global-spacing-unit-small !important;
        }

        margin-left: 32px;
      }
    }
  }

  &__heading {
    border-bottom: 1px solid color-var(border, 2);
    margin-bottom: $inuit-global-spacing-unit-big;
  }

  .c-input--autocomplete {
    border-color: rgba(0, 0, 0, 0.2);
  }

  &--small {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
  }

  &--small &__content {
    border-top: $global-border solid color-var(border);
    border-bottom: $global-border solid color-var(border);
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 8px;

    @include mq($from: large) {
      right: 16px;
    }
  }
}

.c-pccontent {
  @include mq($from: large) {
    margin-top: -40px;
    margin-left: -60px;
    margin-right: -60px;
  }
}

.c-chooser--padded {
  @include mq($from: large) {
    padding: 0 50px;
  }
}
