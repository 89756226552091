.c-event-detail {
  position: relative;

  &__list {
    border: 1px solid color-var(main, 1);
    border-radius: 5px;
  }

  &__flag {
    position: absolute;
    top: $inuit-global-spacing-unit-big;
    right: $inuit-global-spacing-unit-great;
    border: 1px solid #e1e1e1;

    @include mq($until: 'large') {
      position: static;
    }
  }
}

.c-event-list {
  padding: $inuit-global-spacing-unit-tiny + $inuit-global-spacing-unit-big $inuit-global-spacing-unit-great;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: $inuit-global-spacing-unit-great;
    }
  }

  &__item-icon {
    $size: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;
    @include square-style($size);
    text-align: center;
    flex-shrink: 0;
    margin: 0 $inuit-global-spacing-unit-big 0 0;

    img,
    svg {
      height: 100%;
      width: auto;
    }
  }

  &__item-title {
    color: color-var(func, 3);
    @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h5);
    letter-spacing: 0;
    line-height: $inuit-global-spacing-unit-large;
  }

  &__item-text {
    color: color-var(font, 1);
    @include inuit-font-size($inuit-font-size-h4, $inuit-line-height-h4);
    font-weight: bold;
    letter-spacing: 0;
  }
}
