.c-banner {
  display: block;
  padding: 24px 100px 24px 24px;
  border-radius: $global-radius;
  text-align: left;
  border: 1px solid color-var(border);
  position: relative;

  &__icon {
    position: absolute;
    top: 24px;
    right: 24px;
    filter: brightness(0) invert(1);
  }

  .c-button--primary {
    background: transparent;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &--full {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-huge;

    @include bp('medium') {
      padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-huge;
      display: flex;
      text-align: left;
      align-items: center;
    }

    .c-banner {
      &__subtitle {
        @include bp-down('medium') {
          @include inuit-font-size($inuit-font-size-h6, $important: true);
        }

        @include bp('medium') {
          margin-bottom: 0;
        }
      }

      &__title {
        flex: 1;
        padding: 0 $inuit-global-spacing-unit-great;

        @include bp('medium') {
          margin-bottom: 0;
        }
      }
    }
  }

  &-section .c-button--primary {
    background: transparent;
  }
}