.c-form {
  &--inline {
    display: flex;

    .c-input,
    .c-button,
    .c-select__title {
      border-radius: 0;
      margin: 0;
      margin-right: -1px;
      min-height: 15 * $inuit-global-baseline;
      min-width: 33 * $inuit-global-baseline;
    }
    @include bp-down("medium") {
      .c-header & {
        .c-button {
          font-size: 0;
          width: 50px;
          min-width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          .c-icon {
            left: auto;
          }
        }
      }
    }

    .c-select__title {
      min-width: 48 * $inuit-global-baseline;
    }

    .c-label {
      margin-bottom: 0;
    }

    .c-input:first-child {
      border-top-left-radius: $global-radius;
      border-bottom-left-radius: $global-radius;
    }

    .c-button:last-child {
      border-top-right-radius: $global-radius;
      border-bottom-right-radius: $global-radius;
    }
  }
}

form {
  .c-label:has(.wpcf7-not-valid),
  .c-label:has(textarea.has-duplicate) {
    .c-label__title {
      color: var(--color-error-1) !important;
    }
    .wpcf7-not-valid {
      border: 1px solid var(--color-error-1) !important;
    }
  }

  .wpcf7-not-valid {
    border: 1px solid var(--color-error-1) !important;
  }

  .wpcf7-not-valid-tip {
    display: block;
    top: -22px;
  }

  .wpcf7-form-control-wrap {
    display: block;
    padding: 0;
  }

  .c-card__content div.has-error {
    .c-label__error {
      display: block !important;
    }
  }
  textarea.has-duplicate {
    border: 1px solid var(--color-error-1) !important;
  }
}
