.c-gallery {
  &__title,
  &__subtitle {
    margin-bottom: $inuit-global-spacing-unit-tiny;
  }

  &__item {
    margin-top: $inuit-global-spacing-unit-great;
  }
}
