.c-text-less {
  display: block;

  .is-collapsed & {
    display: none;
  }
}

.c-text-more {
  display: none;

  .is-collapsed & {
    display: block;
  }
}

.c-text-icon {
  transition: transform 200ms ease-in-out;

  .is-collapsed & {
    transform: rotate(180deg);
  }
}
