.c-how-it-works {
  background-color: color-var(bg, 5);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @include mq($from: large) {
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-huge 0  ($inuit-global-spacing-unit-large * 2);
  }

  &__step {
    @include mq($from: large) {
      flex-direction: row;
    }
  }

  &__wrapper {
    max-width: 826px;
  }

  &__image {
    margin-right: $inuit-global-spacing-unit-huge;
    border-radius: 5px;
    overflow: hidden;

    @include mq($until: large) {
      margin-right: 0;
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-size: 1.11rem;
  }

  &__footer {
    background-color: #007EF5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__content {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit 0;
    position: relative;

    @include mq($from: large) {
      padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit 0 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
  }

  &__icon {
    width: auto;
    height: 140px;
    filter: none !important;
    position: absolute;
    top: 24px;
    left: 8px;
    z-index: 1;

    @include mq($from: large) {
      height: 120px;
      top: 0;
      left: -16px;
    }
  }

  &__tooltip {
    position: absolute;
    top: $inuit-global-spacing-unit-great;
    right: 0;
    display: none;
    width: 304px;
    max-width: 100%;
    border: 1px solid color-var(border, 1);
    box-shadow: $global-shadow;
    z-index: 2;

    @include mq($from: large) {
      top: -$inuit-global-spacing-unit-large;
      right: auto;
      left: 100%;
      margin-left: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-micro;

      &::before {
        content: '';
        position: absolute;
        top: $inuit-global-spacing-unit-large;
        right: 100%;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid color-var(bg, 1);
      }
    }

    &.is-active {
      display: block;
    }
  }

  &__tooltip-icon {
    cursor: pointer;
    position: absolute;
    top: $inuit-global-spacing-unit-great;
    right: $inuit-global-spacing-unit-tiny;
    z-index: 2;
    color: #343a3f;

    @include mq($from: large) {
      top: 0;
      right: -$inuit-global-spacing-unit-large;
    }

    &:hover {
      color: color-var(font, 4);
    }
  }

  &__tooltip-close {
    cursor: pointer;
    position: absolute;
    top: $inuit-global-spacing-unit-small;
    right: $inuit-global-spacing-unit-small;

    g, path {
      fill: color-var(sub, 2);
    }
  }
}
