// Animations
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.is-fading-out {
  animation: fade-out 300ms ease both !important;
}

@mixin fade-in {
  animation: fade-in 250ms ease both;
}

.u-fade-in {
  @include fade-in;
}