.c-advantage-box {
  width: 100%;
  border-radius: 5px;
  box-shadow: $global-shadow;
  color: initial !important;

  &__icon {
    width: auto;
    height: 3.5rem;
  }
}

.c-advantages-boxes {
  @include mq($from: large) {
    justify-content: center;
  }
}