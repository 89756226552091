.c-icon-text {
  $this: &;

  margin-top: $inuit-global-spacing-unit-big;
  &+& {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &__image {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  &__container {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin-right: $inuit-global-spacing-unit-huge;
  }

  &__content {
    margin-bottom: 0;
  }


}