.c-breadcrumbs {
  @include inuit-font-size($inuit-font-size-h6);
  color: color-var(font, 2);
  // padding: $inuit-global-spacing-unit-large 0;
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
  margin-left: -$inuit-global-spacing-unit-tiny;

  a,
  span {
    display: inline-block;
  }

  a {
    color: color-var(font, 2);
    text-decoration: underline;
    margin: 0 $inuit-global-spacing-unit-tiny;
    @include bp-down('medium') {
      display: none ;
    }
  }
  @include bp-down('medium') {
    span {
      font-size: 0;
    }
  }

  .breadcrumb {
    &_last {
      margin-left: $inuit-global-spacing-unit-tiny;
      @include bp-down('medium') {
        display: none;
      }
    }

    &_last-link {
      display: flex;
      align-items: center;
      font-size: 0;
      span,a{
        font-size: 0.75rem;
      }
      @include bp-down('medium') {
        display: inline-block;

        &:before {
          display: inline-block;
          content: '\003C' ;
          color: color-var(font, 2);
          @include inuit-font-size($inuit-font-size-h6);

          .has-hero & {
            color: color-var(font, 3);
          }
        }

        a {
          @include inuit-font-size($inuit-font-size-h6);
          display: inline-block;
          color: color-var(font, 2);
          text-decoration: underline;
          margin: 0 $inuit-global-spacing-unit-tiny;
        }
      }
      span:first-child a{
        background: url('../assets/images/svg/home.svg') no-repeat center center;
        background-size: cover;
        font-size: 0;
        width: 16px;
        height: 16px;
        margin:0!important;
        display: block;
      }
      span:not(:first-child){
        margin-left: 12px;
        display: flex;
        align-items: center;
        &:before{
          content:"";
          width:4px;
          height: 16px;
          display: block;
          margin-right: 12px;
          background: url('../assets/images/svg/caret.svg') no-repeat center center;
        }

      }
      &:not([style]){
        span:first-child a{
          filter:brightness(0.5);
        }
        span:not(:first-child):before{
          filter:brightness(0.5); 
        }
      }
    }
  }

  body.has-hero & {
    margin-bottom: calc(-#{(2 * $inuit-global-spacing-unit) + 1px} - 2em);
    position: relative;
    z-index: 2;
    color: color-var(font, 3);

    a {
      color: color-var(font, 3);
    }
  }
}
