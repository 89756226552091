.c-add-to-calendar-section {
  &__list {
    margin: $inuit-global-spacing-unit-large 0;
  }
  &__list-item {
    width: 185px;
    color: color-var(font, 4);
    border: 1px solid #979797;
    border-radius: 5px;
    text-align: center;
    padding: $inuit-global-spacing-unit-large;
    @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h5);
    line-height: $inuit-global-spacing-unit-large;
    &:not(:first-child) {
      margin-left: $inuit-global-spacing-unit-big;
    }
    &-icon {
      margin: auto auto $inuit-global-spacing-unit-tiny auto;
      width: $inuit-global-spacing-unit-large;
    }
  }
}
