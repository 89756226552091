.c-widget {
  &__title {
    @include inuit-font-size($inuit-global-font-size);
    font-weight: 400;
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__article {
    margin-bottom: $inuit-global-spacing-unit-great;
  }


  &__context-menu {
    width: 100%;
  }

  @include bp-down('large') {
    &__context-menu {
      position: fixed;
      text-align: left;
      bottom: 0;
      left: 1.25*$inuit-global-spacing-unit-tiny;
      right: 1.25*$inuit-global-spacing-unit-tiny;
      background: color-var(bg, 1);
      border: 1px solid #e1e1e1;
      border-bottom: none !important;
      border-radius: 5px 5px 0 0;
      background-color: #ffffff;
      box-shadow: 0 -18px 24px 0 rgba(0, 0, 0, 0.11);

      .c-context-menu__list {
        display: none;
        margin-top: $inuit-global-spacing-unit-large;
        margin-bottom: $inuit-global-spacing-unit-large;
        max-height: 70vh;
        overflow-y: auto;

        &.is-active {
          display: block;
        }
      }

      .u-text--cta {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: color-var(font, 1) !important;
        text-transform: none;
        @include inuit-font-size($inuit-font-size-h6, $inuit-line-height-h6, $important: true);
        font-weight: 500;
        letter-spacing: 0;
      }
    }
  }

  &__search-filters {
    @include bp('large') {
      margin-top: -$inuit-global-spacing-unit-great;
    }
  }

  &_post-list {
    margin-top: $inuit-global-spacing-unit-great;

    @include mq($from: large) {
      .c-article--event .c-article__desc {
        padding: $inuit-global-spacing-unit-small;
        padding-left: 140px;
      }

      .c-article--event .c-article__title {
        @include inuit-font-size($inuit-font-size-h6, 1.6);
      }
    }
  }
}