.c-card {
  $this: &;
  background: color-var(func, 1);
  padding: $inuit-global-spacing-unit-huge;
  border: 1px solid color-var(border);
  border-radius: 5px;
  @include mq($until: 'medium') {
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-big;
  }

  &--shadow {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.11);
  }

  &__content {
    @extend .block-editor-block-list__block;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.has-larger-font-size {
        font-weight: 400 !important;
        line-height: 1.1875em !important;
      }

      &:not([class]),
      &:not([class]) {
        border-bottom: 1px solid color-var(border, 2);
        padding-bottom: $inuit-global-spacing-unit-small;
      }
    }
  }

  &__form {
    .c-card {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }
}

.js-input-content[data-field='company-fb'],
.js-input-content[data-field='company-li'],
.js-input-content[data-field='company-in'],
.js-input-content[data-field='company-tw'],
.js-input-content[data-field='company-yt'] {
  word-break: break-all;
}
