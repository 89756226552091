.c-post-list {
  &__title {
    margin-bottom: 0;
    @include mq($until: medium) {
      text-align: center;
    }
  }

  &__content {
    // margin-top: $inuit-global-spacing-unit-huge;
    @include mq($until: large) {
      .o-flex--grids {
        flex-wrap: nowrap;
        padding-bottom: $inuit-global-spacing-unit-large;
        transition: transform 0.25s ease-in-out;
      }
    }
    @include mq($until: large) {
      &--wrap {
        flex-wrap: wrap !important;
      }
    }
  }

  &__cta {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &--reversed {
    margin-top: -$inuit-global-spacing-unit-huge * 6;
  }

  &--bordered {
    .c-tease,
    .c-article {
      box-shadow: $global-shadow;
      background: color-var(bg);
      color: color-var(font) !important;

      &.c-tease,
      &.c-article,
      &.c-tease *,
      &.c-article * {
        color: color-var(font) !important;
      }

      .c-tag {
        color: color-var(font, 3) !important;

        &--alternative {
          color: color-var(font, 2) !important;
        }
      }
    }
  }

  &__item {
    margin-top: $inuit-global-spacing-unit-big;
    @include bp('medium') {
      margin-top: $inuit-global-spacing-unit-great;

      .post-type-archive-kalendarz & {
        margin-top: $inuit-global-spacing-unit-large;
      }
    }

    .c-article {
      height: 100%;
      @include bp-down('large') {
        box-shadow: $global-shadow-strong;
      }
    }
  }

  &__slider {
    &-bullets {
      display: flex;
      max-width: 250px;
      margin: $inuit-global-spacing-unit auto $inuit-global-spacing-unit-large;
    }

    &-bullet {
      border: none;
      cursor: pointer;
      background: var(--color-bg-3);
      height: 5px;
      opacity: 0.2;
      margin: 0 5px;
      flex-grow: 1;
      border-radius: 0;

      &.is-active {
        opacity: 1;
      }
    }
  }

  &--grid {
    // @include mq($from: medium) {
    //   display: grid;
    //   grid-template-columns: 7fr 5fr;
    //   grid-template-rows: 1fr 1fr;
    //   grid-template-areas:
    //     "first second"
    //     "first three";
    // }
    @include mq($from: medium) {
      display: grid;
      grid-template-columns: 6fr 3fr 3fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        "first second three";
      grid-gap:24px;
      padding: 0 24px;
    }
    @include mq($until: medium) {
      width: 300%;
    }
    &>div{
      position: relative;
    }

    .c-article--promo {
      @include mq($from: medium) {
        grid-area: first;
        margin-left: $inuit-global-spacing-unit-great;
        width: calc(100% - 32px);
      }
      @include mq($until: medium) {
        margin-left: $inuit-global-spacing-unit-great;
      }
    }

    .c-article--promo-small {
      @include mq($from: medium) {
        margin-left: $inuit-global-spacing-unit-great;
        width: calc(100% - 32px);

        &:nth-of-type(2) {
          grid-area: second;
          margin-bottom: $inuit-global-spacing-unit;
        }

        &:nth-of-type(3) {
          grid-area: three;
          margin-top: $inuit-global-spacing-unit;
        }
      }
      @include mq($until: medium) {
        margin-left: $inuit-global-spacing-unit-great;

        .c-article__image::before {
          padding-bottom: 80.6%;
        }
      }
    }
  }
}
