$baseColors: ( 
                        "main": ( 
                                    #0063C1
                 
            ),
                                "sub": ( 
                                    #DC143C
                                    #525960
                 
            ),
                                "func": ( 
                                    #ffffff
                                    #F1F1F1
                                    #737373
                                    #000000
                 
            ),
                                "success": ( 
                                    #34B53A
                 
            ),
                                "error": ( 
                                    #DC143C
                 
            ),
            	
)!default;
$useColors: ( 
                        "font": ( 
                                    color-var(func,4)
                                    color-var(func,3)
                                    color-var(func,1)
                                    color-var(main,1)
                                    color-var(sub,1)
                                    color-var(success,1)
                                    color-var(error,1)
                            ), 
                                "bg": ( 
                                    color-var(func,1)
                                    color-var(main,1)
                                    color-var(sub,1)
                                    color-var(sub,2)
                                    color-var(func,2)
                                    color-var(success,1)
                                    color-var(error,1)
                                    color-var(func,4)
                            ), 
                                "border": ( 
                                    color-var(func,2)
                                    color-var(main,1)
                                    color-var(sub,1)
                                    color-var(func,1)
                                    color-var(sub,2)
                                    color-var(func,4)
                                    color-var(func,4)
                                    color-var(success,1)
                                    color-var(error,1)
                            ), 
            	
)!default;
			
:root{
                                                                    --color-main-1: #0063C1;
                     
                                                                            --color-sub-1: #DC143C;
                                            --color-sub-2: #525960;
                     
                                                                            --color-func-1: #ffffff;
                                            --color-func-2: #F1F1F1;
                                            --color-func-3: #737373;
                                            --color-func-4: #000000;
                     
                                                                            --color-success-1: #34B53A;
                     
                                                                            --color-error-1: #DC143C;
                     
                    	
                                                                        --color-font-1: #000000;
                                            --color-font-2: #737373;
                                            --color-font-3: #ffffff;
                                            --color-font-4: #0063C1;
                                            --color-font-5: #DC143C;
                                            --color-font-6: #34B53A;
                                            --color-font-7: #DC143C;
                                                                                                --color-bg-1: #ffffff;
                                            --color-bg-2: #0063C1;
                                            --color-bg-3: #DC143C;
                                            --color-bg-4: #525960;
                                            --color-bg-5: #F1F1F1;
                                            --color-bg-6: #34B53A;
                                            --color-bg-7: #DC143C;
                                            --color-bg-8: #000000;
                                                                                                --color-border-1: #F1F1F1;
                                            --color-border-2: #0063C1;
                                            --color-border-3: #DC143C;
                                            --color-border-4: #ffffff;
                                            --color-border-5: #525960;
                                            --color-border-6: #000000;
                                            --color-border-7: #000000;
                                            --color-border-8: #34B53A;
                                            --color-border-9: #DC143C;
                                        	
    			
}
@mixin lightMode(){
                                                                    --color-main-1: #0063C1;
                     
                                                                            --color-sub-1: #DC143C;
                                            --color-sub-2: #525960;
                     
                                                                            --color-func-1: #ffffff;
                                            --color-func-2: #F1F1F1;
                                            --color-func-3: #737373;
                                            --color-func-4: #000000;
                     
                                                                            --color-success-1: #34B53A;
                     
                                                                            --color-error-1: #DC143C;
                     
                    	
                                                                        --color-font-1: #000000;
                                            --color-font-2: #737373;
                                            --color-font-3: #ffffff;
                                            --color-font-4: #0063C1;
                                            --color-font-5: #DC143C;
                                            --color-font-6: #34B53A;
                                            --color-font-7: #DC143C;
                                                                                                --color-bg-1: #ffffff;
                                            --color-bg-2: #0063C1;
                                            --color-bg-3: #DC143C;
                                            --color-bg-4: #525960;
                                            --color-bg-5: #F1F1F1;
                                            --color-bg-6: #34B53A;
                                            --color-bg-7: #DC143C;
                                            --color-bg-8: #000000;
                                                                                                --color-border-1: #F1F1F1;
                                            --color-border-2: #0063C1;
                                            --color-border-3: #DC143C;
                                            --color-border-4: #ffffff;
                                            --color-border-5: #525960;
                                            --color-border-6: #000000;
                                            --color-border-7: #000000;
                                            --color-border-8: #34B53A;
                                            --color-border-9: #DC143C;
                                        	
    			
}