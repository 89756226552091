@include inuit-flexwidths($inuit-fractions);
/*
    ==========================================================================||
    Similar to inuitcss utilities.widths, $mq-breakpoints defined allows
    you to do something like this for additional control via markup only

    e.g.
    <div class="o-flex__item u-flex-1/2@mobile"></div>
    <div class="o-flex__item u-flex-1/3@tablet u-flex-offset-1/3@tablet"></div>
    <div class="o-flex__item u-flex-1/4@desktop"></div>
    <div class="o-flex__item u-flex-1/5@wide u-flex-offset-2/5@wide"></div>
    ==========================================================================||
*/

@if (variable-exists(mq-breakpoints)) {

  @each $bp-name,
  $bp-value in $mq-breakpoints {
    @include mq($from: $bp-name) {
      @include inuit-flexwidths($inuit-fractions,
        #{$inuit-widths-breakpoint-separator}#{$bp-name});
    }
  }
}

.u-flex-\[80px\] {
  padding: 0 !important;
  width: 80px !important;
  height: 80px !important;
  flex: 0 0 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}