.c-agenda-section {
	&__tab-list {
		margin-top: $inuit-global-spacing-unit-huge ;
	}
	&__tab-text {
		margin: $inuit-global-spacing-unit-tiny + $inuit-global-spacing-unit-huge 0;
	}
}
.c-agenda-tab-text {
	&__item {
		& + & {
			margin-top: $inuit-global-spacing-unit-great ;
		}
	}
	&__item-time {

	}
	&__item-title {
		font-weight: 700;
	}
	&__item-text {
		margin-top: $inuit-global-spacing-unit;
	}
}