/* ==========================================================================
   #RADIO BUTTON
   ========================================================================== */

.c-radio {
  @include clickableInput();

  &__replacement {
    border-radius: 50%;

    &:before {
      @include absolutePseudoContent();
      @include square-style($inuit-font-size-h6);
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
