.c-export-list-section {
  &__list {
    margin-top: $inuit-global-spacing-unit-great;
    margin-bottom: $inuit-global-spacing-unit-great;

    &-item {
      margin-bottom: $inuit-global-spacing-unit-great;

      &-img {
        width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
        height: auto;
        margin-right: $inuit-global-spacing-unit-big;
        border: 1px solid #e1e1e1;
        margin-bottom: 0 !important;
      }

      &-link {
        display: inline-flex;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28.8px;
        text-decoration: underline;
        color: color-var(font, 4);
        @include inuit-font-size($inuit-font-size-h4, $inuit-line-height-h4);

        &:hover {
          text-decoration: none;
        }
      }
    }

    &-link {
      order: 999;
      border: none;
      padding: 0 24px 0 0;
      background: none;
      appearance: none;
      margin-left: $inuit-global-spacing-unit-great;
      @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h5);
    }

    &-more {
      margin-left: 0;
      width: 100%;
      display: none;

      &.is-active {
        display: flex;
      }
    }
  }
}
