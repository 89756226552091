.c-icons {

  &__title,
  &__subtitle {
    text-align: left;
  }

  &__content {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &__icon {
    margin-bottom: $inuit-global-spacing-unit-huge;
    border-radius: 50%;
    width: 150px;
    height: auto;

    &-title {
      font-weight: 700;
    }

    &-desc {
      @include inuit-font-size($inuit-font-size-h5);
      margin-bottom: 0 auto $inuit-global-spacing-unit-huge;
    }
  }
}
