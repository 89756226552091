.c-hero {
  $this: &;

  position: relative;

  &:before {
    display: none;
  }

  &__title,
  &__subtitle {
    color: color-var(font, 3);
    margin-bottom: 0;
  }

  &__title {
    @include inuit-font-size($inuit-font-size-h0);
    font-weight: 400;
    @include mq($until: 'medium') {
      @include inuit-font-size($inuit-font-size-h1);
    }
  }

  &__subtitle {
    font-weight: 400;
    margin: $inuit-global-spacing-unit-small auto 0;
    @include inuit-font-size($inuit-font-size-h5);
    @include mq($from: large) {
      max-width: 66%;
      @include inuit-font-size($inuit-font-size-h4);
    }
  }

  &__content {
    text-align: center;
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &--small {
    // min-height: 230px;
    min-height: 56px;
    *{
      display: none!important;
      font-size: 0px!important;
    }
  }

  &--normal {
    min-height: 330px;
  }

  &--big {
    min-height: 400px;
  }

  &--full {
    min-height: 710px;
    padding-top: 110px;
    margin-top: -110px;
  }

  &--secondary {
    .c-hero {
      &__content {
        text-align: left;
      }

      &__container {
        justify-content: flex-start;
      }
    }
  }

  &:after {
    @include absolutePseudoContent();
    @include absPosition100();
    pointer-events: none;
  }

  @each $type, $colors in $baseColors {
    $iter: 1;

    @each $color in $colors {
      &.u-color--#{$type}-#{$iter}:after {
        background-color: $color !important;
        opacity: 0.8;
      }

      $iter: $iter + 1;
    }
  }

  &__video {
    position: absolute;
    @include absPosition100();
    pointer-events: none;
    overflow: hidden;

    .c-embed {
      position: relative;
      padding-bottom: 56.25%;
      /* 16:9 */
      height: 0;

      iframe {
        position: absolute;
        top: -70px;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--search-results {
    background: color-var(bg, 2);
    color: color-var(font, 3);
    padding-top: $inuit-global-spacing-unit-massive;
    padding-bottom: $inuit-global-spacing-unit-huge;

    #{$this}__title {
      margin-bottom: 0;
      @include mq($until: 'large') {
        margin-bottom: $inuit-global-spacing-unit-small;
      }
    }

    #{$this}__content {
      width: 100%;
    }
  }

  &--with-breadcrumbs {
    padding-top: 0;
    padding-bottom: 0;

    #{$this}__title {
      margin-bottom: $inuit-global-spacing-unit-small;
    }

    #{$this}__content {
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    max-width: 670px;
    margin: auto;
  }

  .c-label {
    margin-bottom: 0;
    margin-top: $inuit-global-spacing-unit-great;
  }

  @include mq($until: large) {
    padding: 80px 0 50px;
  }
}


.c-hero-lp {
  position: relative;

  @include mq($from: medium) {
    max-height: 700px;
    overflow: hidden;
  }

  &__container {
    width: 100%;
    position: relative;
    z-index: 1;

    @include mq($from: medium) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__wrapper {
    @include mq($until: medium) {
      padding: 0;
    }
  }

  &__box {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: -10px;

    @include mq($from: medium) {
      margin-bottom: $inuit-global-spacing-unit-great * 2;
      padding: $inuit-global-spacing-unit-great ($inuit-global-spacing-unit-great + $inuit-global-spacing-unit);
      border-radius: 5px;
      max-width: 32.25rem;
      margin-top: 0;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;

    @include mq($until: medium) {
      width: auto;
      max-height: 315px;
      left: 50%;
      transform: translateX(-50%);
    }

    @include mq($from: medium) {
      transform: translateX(0) !important;
    }
  }

  &__title {
    font-family: $global-heading-font-family;
    font-size: 1.5rem;
    line-height: 1.5;

    @include mq($from: medium) {
      font-size: 2rem;
    }
  }
}
