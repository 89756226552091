.c-embed {
  &--video {
    height: 100%;
  }
}

.wp-block-embed {
  margin-bottom: 1em;
}

.wp-block-embed figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.wp-block-embed iframe {
  max-width: 100%;
}

.wp-block-embed__wrapper {
  position: relative;
}

.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%;
}

.wp-embed-responsive .wp-has-aspect-ratio iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wp-embed-responsive .wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
  padding-top: 42.85%;
}

.wp-embed-responsive .wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
  padding-top: 50%;
}

.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
  padding-top: 56.25%;
}

.wp-embed-responsive .wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
  padding-top: 75%;
}

.wp-embed-responsive .wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
  padding-top: 100%;
}

.wp-embed-responsive .wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
  padding-top: 177.77%;
}

.wp-embed-responsive .wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  padding-top: 200%;
}
