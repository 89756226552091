.c-region-list-section {
	.c-article {
		display: flex;
	}
	.c-heading {
		font-weight: bold;
		flex-grow: 1 ;
	}
	&__image {
		padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-large 0 $inuit-global-spacing-unit-large;
		height: 280px;
		@include bp("medium") {
			height: 356px;
		}
		&-elem {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}	
	}
	&__desc {
		border-top: none ;
		flex-grow: 1;
		a {
			color: color-var(font, 4);
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		.o-link {
			margin-right: auto;
		}
	}
}
