/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.c-heading {
  position: relative;
  font-weight: $global-heading-font-weight;
  font-family: $global-heading-font-family;
  margin-bottom: $inuit-global-spacing-unit;
  @include inuit-font-size($inuit-font-size-h4);
  z-index: 1;

  &--h0 {
    font-family: $global-body-font-family;
    font-weight: 600;
    @include inuit-font-size(27px, 1.2em);
  }

  &--h1 {
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
  }

  &--h2 {
    @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h5);
    // font-family: $global-body-font-family;
    // font-weight: 500;
  }

  &--h3 {
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    font-family: $global-body-font-family;
    font-weight: 500;
  }

  &--h4 {
    @include inuit-font-size($inuit-font-size-h4, $inuit-line-height-h4);
    font-family: $global-body-font-family;
    font-weight: 500;
  }

  &--bordered {
    border-bottom: 1px solid color-var(border, 2);
    padding-bottom: $inuit-global-spacing-unit-big;
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &--sans-serif {
    font-family: $global-body-font-family;
  }

  &--serif {
    font-family: $global-heading-font-family;
  }
}