/* stylelint-disable max-nesting-depth */
/* ==========================================================================
   #article
   ========================================================================== */

.c-article {
  $this: &;

  display: block;
  position: relative;
  background: color-var(bg);
  text-decoration: none !important;
  @include inuit-font-size($inuit-font-size-h5);
  border-radius: $global-radius;
  overflow: hidden;
  width: 100%;
  border: $global-border solid color-var(border);

  &:hover {
    color: inherit;
  }

  &:not(.c-article--compact-topic):not(.c-article--main-topic):not(.c-article--normal):not(.c-article--main):hover {
    box-shadow: $global-shadow;
  }

  &__place,
  &__time {
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 16px;
      margin-right: $inuit-global-spacing-unit-micro;
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $inuit-global-spacing-unit;
    @include inuit-font-size($inuit-font-size-h6);
  }

  &__date {
    color: color-var(font, 2);
  }

  &__format {
    background: #e8e8e8;
    color: #454545;
    border-radius: $global-radius;
    @include inuit-font-size($inuit-font-size-h6);
    padding: $inuit-global-spacing-unit-micro/2 $inuit-global-spacing-unit-small;
  }

  &__title {
    min-height: 4.375em;
  }

  &__desc {
    background: color-var(bg);
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit;
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    width: 100%;
    border-top: $global-border solid color-var(border);

    @include bp('medium') {
      padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit;
    }

    &--greater-padding-bottom {
      padding-bottom: $inuit-global-spacing-unit-huge * 1.5;
    }
  }

  &__excerpt {
    margin-bottom: $inuit-global-spacing-unit-large;

    @include bp('medium') {
      min-height: 12.5em;
    }
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 66.76%;

      .c-article--promo & {
        padding-bottom: 80.6%;
      }

      .c-article--promo-small & {
        padding-bottom: 37.02%;
      }

      .c-article--list & {
        padding-bottom: 88.24%;
      }
    }

    &-elem {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      margin: auto;

      &.lazyloaded {
        opacity: 1;
        transition: opacity 400ms;
        transition-delay: 0ms;
      }
    }

    &:not(.c-article__image--full) {
      .c-article__image-elem {
        width: 100%;
        height: 100%;
      }
    }

    &--full {
      .c-article__image-elem {
        max-width: 90%;
        max-height: 90%;
        height: auto;
        margin: auto;
      }
    }
  }

  &__link {
    position: absolute;
    bottom: $inuit-global-spacing-unit-great;
  }

  &--horizontal,
  &--big {
    display: flex;
  }

  &--horizontal {

    .c-article__desc,
    .c-article__image {
      flex: 1;
    }

    .c-article__image {
      max-width: 270px;
    }

    .c-article__desc {
      padding: 0;
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &--big {
    .c-article__desc {
      flex: 7;
      padding: $inuit-global-spacing-unit;
      @include inuit-font-size($inuit-font-size-h4);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      &:after {
        content: '';
        flex: 0 0 100%;
      }
    }

    .c-article__meta,
    .c-article__content {
      flex: 0 0 100%;
    }

    .c-article__image {
      flex: 5;
      order: 2;

      &:before {
        padding-bottom: 100%;
      }
    }
  }

  &--promo-small,
  &--promo {
    .c-article__title {
      min-height: 2.459em;
    }
  }

  &--simple {
    background: transparent;
    @include inuit-font-size($inuit-font-size-h4);
    padding: $inuit-global-spacing-unit-tiny 0;
    border-bottom: $global-border solid color-var(border);

    .c-article__title {
      margin-bottom: 0;
    }

    &:hover {
      .c-article__title {
        color: color-var(font, 4);
      }
    }
  }

  &--list {
    @include mq($from: large) {
      display: flex;

      .c-article__image {
        flex: 0 0 170px;
      }

      .c-article__title {
        min-height: 1.459em;
      }
    }

    @include mq($until: medium) {
      .c-article__image {
        display: none;
      }
    }
  }

  &--compact {
    border-radius: 0;
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-great;
    padding-right: $inuit-global-spacing-unit-large;
    position: relative;
    border: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 2.5 * $inuit-global-baseline;
      height: 100%;
      background: color-var(bg, 3);
      opacity: 0.2;
      @include transition();
    }

    &:hover,
    &:active,
    &.is-active {
      &:before {
        opacity: 1;
      }
    }

    .c-article {

      &__title,
      &__excerpt {
        min-height: 1px;
        margin-bottom: 0;
      }

      &__excerpt {
        color: color-var(font, 2);

        .c-icon {
          position: static;
          display: inline-block;
          padding-top: 6px;
          padding-left: 4px;

          vertical-align: middle;

          svg {
            width: 0.85em;
            height: 0.85em;
          }

          * {
            fill: color-var(font, 2);
          }
        }
      }
    }
  }

  &--compact-topic {
    border-radius: 0;
    padding: 0 $inuit-global-spacing-unit;
    border: 0;

    .c-article {

      &__title,
      &__excerpt {
        min-height: 1px;
        margin-bottom: 0;
      }
    }
  }

  &--main-topic,
  &--main {
    background: transparent;
    border-radius: 0;
    padding: 0 $inuit-global-spacing-unit;
    border: 0;

    .c-article {

      &__title,
      &__excerpt {
        min-height: 1px;
        margin-bottom: 0;
        background: #fff;
        display: inline;
        padding: 4px 8px;
        line-height: 2.2em;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
      }

      &__excerpt {
        font-size: 16px;
      }
    }
  }

  &--main {
    position: absolute;
    bottom: $inuit-global-spacing-unit-large;
    left: $inuit-global-spacing-unit-large;
    height: auto !important;

    .c-article__excerpt {
      min-height: 1px;
    }
  }

  &--normal {
    height: auto !important;
    border: 0;
    padding: 8px 0 0 0;

    .c-article__title {
      line-height: 1.3em !important;
      min-height: 1px;
      margin-bottom: 8px;
    }

    .c-article__excerpt {
      min-height: 1px;
      font-size: 14px;
      color: #737373;
      margin-bottom: 0 !important;
    }

  }

  &__extra {
    @include inuit-font-size($inuit-font-size-h6);
    color: color-var(font, 2);
  }

  &--event {
    border-radius: $global-radius;
    border: $global-border solid #E1E1E1;
    overflow: hidden;

    .c-article {
      &__desc {
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small;
        padding-left: 4.25 * $inuit-global-spacing-unit-big;
        position: relative;
        border: none;
        height: 100%;

        @include bp('medium') {
          padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
          padding-left: 55 * $inuit-global-baseline;
        }

        @include mq($until: large) {
          padding-left: $inuit-global-spacing-unit-small;
          padding-top: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-small;
        }
      }

      &__title {
        margin-bottom: $inuit-global-spacing-unit-micro;
        font-weight: bold;
        line-height: 1.6;
        color: color-var(font, 4);
        min-height: auto;
        order: 1;

        @include mq($until: 'medium') {
          font-size: 1rem;
          line-height: 1.5;
          margin-bottom: $inuit-global-spacing-unit-tiny;
        }
      }

      &__excerpt,
      &__meta {
        min-height: 1px;
        margin-bottom: 0;
      }

      &__excerpt {
        @include inuit-font-size($inuit-font-size-h6, 1.1);
        order: 2;
        margin-bottom: $inuit-global-spacing-unit-large !important;

        @include bp('medium') {
          @include inuit-font-size($inuit-font-size-h6, 24/14);
          margin-bottom: $inuit-global-spacing-unit-great !important;
        }
      }

      &__meta {
        height: 0;
      }

      &__date {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 200px;
        color: color-var(font, 1);
        background: color-var(bg, 5);
        @include inuit-font-size($inuit-font-size-h4, 1.1em);
        font-weight: 500;

        .o-layout__sidebar & {
          width: 128px;
        }

        @include bp('medium') {
          width: 200px;
          @include inuit-font-size($inuit-font-size-h1, 1.5);

          .o-layout__sidebar & {
            width: 128px;
          }
        }

        .u-color--func-2 & {
          background-color: #d8d8d8;
        }

        .c-widget_post-list & {
          background-color: color-var(bg, 3);
          color: color-var(font, 3) !important;
        }

        @include mq($until: large) {
          height: auto;
          width: 100%;
          padding: $inuit-global-spacing-unit-tiny 0;
          @include inuit-font-size($inuit-font-size-h6, 24/14);
          font-weight: bold;
        }

        @include mq($until: medium) {
          height: 72px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }

        &-content {
          height: calc(100% - 46px);

          .o-layout__sidebar & {
            height: 100%;
          }

          &--full {
            height: 100%;
          }

          @include mq($until: medium) {
            width: 50%;
          }
        }

        &-footer {
          height: 46px;
          color: color_var(font, 3);
          background-color: color_var(bg, 3);
          @include inuit-font-size($inuit-font-size-h6, 1);
          font-weight: normal;
          text-align: center;
          padding: $inuit-global-spacing-unit;

          @include mq($until: medium) {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include inuit-font-size($inuit-font-size-h6, 24/14);

            span,
            strong {
              display: block;
            }
          }
        }

        .c-heading {
          line-height: 1em;
        }
      }

      &__eventtime {
        @include inuit-font-size($inuit-font-size-h6, 1);
        margin-top: $inuit-global-spacing-unit-tiny;
        font-weight: normal;
        text-align: center;

        @include mq($until: medium) {
          @include inuit-font-size($inuit-font-size-h6, 24/14);
          margin-top: 0;
        }
      }

      &__label {
        border: 1px solid color_var(border, 1);
        border-radius: 5px;
        color: color_var(font, 4);
        font-size: 0.75rem;
        line-height: 1;
        padding: $inuit-global-spacing-unit-tiny;
        margin-bottom: $inuit-global-spacing-unit-micro;
        text-align: center;

        @include mq($until: medium) {
          margin-right: auto;
        }
      }

      &__footer {
        order: 4;
      }

      &__content {
        display: flex;
        flex-wrap: wrap;

        &>*:not(.o-link) {
          flex: 0 0 100%;
        }

        .o-link {
          order: 5;
        }
      }
    }

    &.c-article--past {
      .c-article__excerpt {
        display: none;
      }
    }
  }

  &--calendar {
    #{$this}__calendar {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      background: color-var(bg, 1);
      align-items: center;
      border-radius: 5px;
      overflow: hidden;
      width: 120px;

      #{$this}__date {
        width: 100%;
        color: color-var(font, 3);
        background: color-var(bg, 3);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include inuit-font-size($inuit-font-size-h1, 1em);
        font-weight: 500;
        padding: $inuit-global-spacing-unit-large;

        .c-heading {
          line-height: 1em;
          font-weight: 500;
        }
      }

      #{$this}__place {
        padding: 1.2 * $inuit-global-spacing-unit-tiny;
        padding-right: 1.4 * $inuit-global-spacing-unit-tiny;
        display: flex;
        gap: 3px;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0;
        margin-right: auto;
      }
    }

    &-small {
      padding: 15px;
      min-height: 110px;


      .c-article__date {
        width: 100px;
        height: 100px;
        background-color: #737373 !important;
        color: #fff !important;
        border-radius: 3px;
        font-size: 16px;
        line-height: 1em;
      }

      .c-article__desc {
        padding: 0 20px;
        padding-left: 115px;
      }

      .c-article__title {
        font-weight: 700;
        color: #000 !important
      }
    }
  }

  &--special {
    text-align: center;

    .c-article {
      &__title {
        color: color-var(font, 4);
        font-family: $global-body-font-family;
        min-height: 1px;

        @include bp-down('large') {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      &__desc {
        height: 100%;
      }

      &__icon {
        display: inline-block;
        margin: 0 auto $inuit-global-spacing-unit-big;

        @include bp('medium') {
          margin: 0 auto $inuit-global-spacing-unit-great;
        }
      }

      &__excerpt {
        text-align: left;
        padding-top: $inuit-global-spacing-unit-small;
        padding-left: $inuit-global-spacing-unit-small;

        @include bp('medium') {
          min-height: 8.5em;
        }

        ul {
          margin-bottom: 0;

          li {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--secondary {
    text-align: left !important;

    .c-article {
      &__desc {
        padding-left: $inuit-global-spacing-unit-large;
        padding-right: $inuit-global-spacing-unit-large;

        @include bp('medium') {
          padding-left: $inuit-global-spacing-unit-great;
          padding-right: $inuit-global-spacing-unit-great;
        }
      }

      &__excerpt {
        padding-left: 0;
      }
    }
  }

  &__category {
    @include inuit-font-size($inuit-font-size-h6);
    font-weight: 500;
    padding: $inuit-global-spacing-unit-tiny 0;
    margin-bottom: 0;
    order: 4;

    @include bp('medium') {
      padding: $inuit-global-spacing-unit-big 0;
    }
  }

  @include mq($until: medium) {
    &--main &__excerpt {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      /* autoprefixer: off */
      -webkit-line-clamp: 6;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: $inuit-global-line-height * 6;
    }
  }
}

.c-article__image--country img {
  width: auto !important;
  height: auto !important;
  display: block !important;
  opacity: 1 !important;
  border: 1px solid #fff;
  max-height: 90px;
}

.c-article__image--country {
  background: #313131;
  background-image: none !important;
}

.c-counter__container {
  min-height: 12.5em;
}

.c-article-company {
  display: flex;

  &__title {
    @include mq($until: large) {
      font-size: 1rem !important;
    }

    .c-article--main & {
      @include mq($from: large) {
        line-height: 1.5 !important;
        height: calc(3 * 1.5 * 1rem);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: color_var(bg, 1);
        position: absolute;
        z-index: 1;

        &:hover {
          overflow: visible;
          height: auto;
          width: calc(100% + 1rem);
          display: block !important;
        }
      }
    }
  }

  &__title-container {
    .c-article--main & {
      @include mq($from: large) {
        height: calc(3 * 1.5 * 1rem);
        position: relative;
      }
    }
  }

  &__image {
    width: 100px;
    height: 80px;
    border: $global-border solid color-var(border);
    border-radius: $global-radius;

    @include mq($from: large) {
      width: 115px;
      height: 92px;
    }
  }

  &__image-elem {
    max-width: 100% !important;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &__industries {
    height: 4.44rem;
    overflow: hidden;
    position: relative;
    margin-top: -$inuit-global-spacing-unit-micro;
  }

  &__industry {
    border: $global-border solid color-var(border);
    border-radius: $global-radius;
    @include inuit-font-size(12px, 1.1);
  }

  &__industries-all {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: color-var(bg);
    box-shadow: 5px 5px 10px 10px color-var(bg);
    text-decoration: underline;
    @include inuit-font-size(16px, 1.1);
    padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;

    .c-popup & {
      visibility: hidden;
    }
  }

  &__link {
    color: color-var(font, 4);
    @include transition();

    &--border {
      border-left: $global-border solid color-var(border);
    }

    &-text {
      @include inuit-font-size(16px, 1);
      text-decoration: underline;
    }

    &:hover {
      color: color-var(font, 5);
    }
  }

  &__excerpt {
    border-top: $global-border solid color-var(border);

    &-container {
      height: calc(3 * 1.76 * 1rem);
      overflow: hidden;
      position: relative;

      &.is-overflow:not(.u-overflow-visible) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
      }
    }
  }

  &__footer {
    border-top: $global-border solid color-var(border);
    margin-top: auto;
  }
}

.c-podcast{
  width: 32px;
  height: 32px;
  background: color-var(bg,3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  left: 12px;
  bottom: 12px;
}
.c-article--podcast{
  .c-button--smallest{
    color: color-var(font, 2);
  }
}

.c-select__item[data-value=""]{
  display: none;
}