.o-section {
  padding: $inuit-global-spacing-unit-great 0;
  position: relative;

  &:before {
    content: "";
    display: block;
    top: 0;
    left: -50%;
    width: 200vw;
    height: 1px;
    background: color-var(border);
    position: absolute;
  }

  .o-composer &:first-child:not(.c-hero),
  &--no-top {
    padding-top: 0;

    &:before {
      display: none;
    }
  }

  &.u-color--func-2 {
    position: relative;

    &:before,
    &:after {
      content: "";
      height: 100%;
      width: 60vw;
      position: absolute;
      top: 0;
      background: #f1f1f1;
      z-index: -1;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;

    }
  }
}