.c-main-topics {
  padding: $inuit-global-spacing-unit-huge 0 $inuit-global-spacing-unit-large !important;

  &__title {
    color: color-var(font, 2);
    margin-bottom: 0;

    @include bp-down('large') {
      text-align: center;
      margin-top: $inuit-global-spacing-unit-big;
      margin-bottom: -$inuit-global-spacing-unit-small;
    }

    @include bp('large') {
      margin-bottom: $inuit-global-spacing-unit-huge;
      margin-top: $inuit-global-spacing-unit-large;
    }
  }

  &__subtitle {
    color: color-var(font, 2);
    margin-right: $inuit-global-spacing-unit;
    margin-bottom: 0;

    @include bp-down('large') {
      text-align: center;
      margin-right: 0;
      margin-bottom: $inuit-global-spacing-unit;
    }
  }

  &__image {
    opacity: 0;
    position: absolute;
    right: 0;
    left: $inuit-global-spacing-unit-great;
    right: 0;
    height: 570px;
    width: auto;
    @include transition('opacity', 'linear', 0.5s);

    @include bp('large') {
      top: -$inuit-global-spacing-unit-great;
    }

    &-container {
      position: relative;
      // height: 538px;
    }

    &.is-active {
      opacity: 1;
    }
  }

  &__tags {
    @include bp-down('large') {
      display: block;
    }

    .c-main-topics__tags-button {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // @include bp("large") {
      //   margin-right: 0;
      //   margin-left: $inuit-global-spacing-unit-tiny;
      // }
      svg:nth-child(1) {
        width: 60px;
      }

      svg:nth-child(3) {
        width: 16px;
      }
    }

    .c-main-topics__tag-name {
      padding: 10px;
      flex: 1;
      font-weight: 700;
      font-size: $inuit-global-font-size - 4px;
    }
  }

  &__main-article {
    position: relative;
    max-width: 80%;

    .c-article {
      max-width: 80%;
      position: absolute;
      left: $inuit-global-spacing-unit-great;
      bottom: $inuit-global-spacing-unit-great;
    }
  }

  &__sub-article {
    display: flex;
    margin-bottom: $inuit-global-spacing-unit-big;
  }

  &__slider-container {
    padding-left: $inuit-global-spacing-unit-great;
    max-width: 100%;
  }

  &__slider-mobile {
    padding-bottom: $inuit-global-spacing-unit-large;
    border-bottom: 1px solid color-var(border, 1);

    .c-article {
      padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large $inuit-global-spacing-unit-small;

      &:before {
        display: none;
      }

      &__title {
        @include inuit-font-size($inuit-font-size-h3);
      }

      &__excerpt {
        @include inuit-font-size($inuit-font-size-h6);
      }
    }

    .glide__slides {
      margin: 0;
    }

    &-track {
      overflow: visible;
    }

    img {
      width: 100%;
      height: 390px;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }

    &-nav {
      display: flex;
      max-width: 250px;
      margin: $inuit-global-spacing-unit auto 0;

      &-bullet {
        border: none;
        cursor: pointer;
        background: color-var(bg, 3);
        height: 5px;
        opacity: 0.2;
        margin: 0 5px;
        flex-grow: 1;
        border-radius: 0;

        &.glide__bullet--active {
          opacity: 1;
        }
      }
    }
  }

  // .c-article__title {
  // @include mq($from: 'large') {
  //   @include inuit-font-size($inuit-font-size-h1);
  // }
  // }
}