.c-horizontal-nav {
  $this: &;
  @include inuit-font-size($inuit-font-size-h5);
  margin-bottom: 0;
  position: relative;
  // @include mq($until: large) {
  //   flex: 1;
  // }

  &--mobile {
    display: none !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 100vw;
    opacity: 0.2;
    background: color-var(border, 3);
    // @include mq($until: large) {
    //   display: none !important;
    // }
  }

  &__item {
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    padding-bottom: $inuit-global-spacing-unit-small;
    cursor: pointer;

    // @include mq($until: large) {
    //   display: block !important;
    //   margin-bottom: $inuit-global-spacing-unit-small;
    // }

    @include mq($from: large) {
      &:not(:first-child) {
        margin-left: $inuit-global-spacing-unit-great;
      }
    }

    &.is-active {
      font-weight: 500;
      color: color-var(font, 5);
    }

    &:hover,
    &.is-active {
      border-color: color-var(border, 3);
    }
  }

  &__target {
    &-item {
      &:not(.is-active) {
        display: none;
      }
    }

    .c-post-list__content {
      margin-top: 0;
    }
  }

//   @include mq($until: 'large') {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     position: relative;
//     width: 90%;

//     &__item {
//       color: color-var(font, 1) !important;
//       background: color-var(bg, 1);
//       border-bottom: none;
//       border-top: none;
//       border-left: 1px solid color-var(border, 2) !important;
//       border-right: 1px solid color-var(border, 2) !important;
//       font-weight: 400 !important;
//       text-align: left;
//       padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-large;
//       margin: 0;
//       display: none !important;

//       &.is-active {
//         border: 1px solid color-var(border, 2);
//         border-radius: $global-radius-input;
//         display: block !important;
//         position: relative;

//         &::after {
//           content: '';
//           border-bottom: 2px solid color-var(border, 2);
//           border-left: 2px solid color-var(border, 2);
//           width: $inuit-global-spacing-unit-tiny;
//           height: $inuit-global-spacing-unit-tiny;
//           position: absolute;
//           top: 40%;
//           right: $inuit-global-spacing-unit-big;
//           transform: rotate(-45deg) skew(5deg, 5deg);

//           #{$this}.is-active & {
//             transform: rotate(135deg) skew(5deg, 5deg);
//           }
//         }
//       }
//     }

//     &.is-active {
//       border-bottom: 1px solid color-var(border, 2);

//       #{$this}__item {
//         display: block !important;

//         &.is-active {
//           border-bottom-left-radius: 0;
//           border-bottom-right-radius: 0;
//         }
//       }
//     }
//   }
}
