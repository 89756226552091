.c-file {
  display: flex;
  align-items: flex-start;
  margin-bottom: $inuit-global-spacing-unit-great;

  &__icon {
    &,
    svg {
      width: 60px;
      height: auto;
      margin-bottom: 0 !important;
    }
  }

  &__data {
    padding-left: $inuit-global-spacing-unit-large;
    position: relative;
  }

  &__name {
    color: color-var(font, 4);
    text-decoration: underline;
    @include inuit-font-size($inuit-font-size-h5);
    margin-bottom: 0 !important;
    word-break: break-all;
  }

  &__desc {
    text-transform: uppercase;
    color: color-var(font, 2);
    @include inuit-font-size($inuit-font-size-h6);
    margin-bottom: 0 !important;
  }

  &__description {
    @include inuit-font-size($inuit-font-size-h6);
    color: color-var(font, 2);
    padding-left: $inuit-global-spacing-unit-small;
  }

  &__file {
    padding-right: $inuit-global-spacing-unit-small;
  }

  &__heading {
    font-weight: 700;
  }

  &__image {
    max-width: 100%;
    margin-bottom: $inuit-global-spacing-unit-small;
  }

  &__remove {
    color: color-var(font, 3) !important;
    background: color-var(error);
    @include square-style($inuit-global-spacing-unit-great);
    border-radius: 50%;
    display: block;
    text-decoration: none !important;
    @include inuit-font-size($inuit-font-size-h3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: calc(100% + #{$inuit-global-spacing-unit-micro});
    cursor: pointer;
  }

  &:hover {
    .c-file__name {
      color: color-var(font, 5);
    }
  }
}
