/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  display: block;

  &__sidebar {
    // @include bp('large') {
    //   padding-left: 2 * $inuit-global-spacing-unit-great !important;
    // }

    &--with-bg {
      position: relative;
      background: color-var(bg, 5);
      padding: 40px 0;

      &:before,
      &:after {
        content: "";
        height: 100%;
        width: 60vw;
        position: absolute;
        top: 0;
        background: #f1f1f1;
        z-index: -1;
      }

      &:before {
        left: 50%;
      }

      &:after {
        right: 50%;

      }

      // @include bp-down('large') {
      //   padding-top: $inuit-global-spacing-unit-big;
      // }

      // &:before {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: -$inuit-global-spacing-unit-big;
      //   background: color-var(bg, 5);
      //   height: 100%;
      //   z-index: -1;
      //   @include bp('large') {
      //     width: 50vw;
      //     right: auto;
      //     top: -$inuit-global-spacing-unit-great;
      //     left: $inuit-global-spacing-unit-great;
      //     height: calc(100% + #{2 * $inuit-global-spacing-unit-great});
      //   }
      // }
    }
  }

  &--with-sidebar {
    .o-layout__content .o-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    // &-sticky {
    //   .o-layout__sidebar {
    //     position: sticky;
    //     top: 30px;
    //     z-index: 2000;
    //   }
    // }
  }

  .single &__content.u-padding-top-huge\@less-then-large {
    @include mq($until: 'large') {
      padding-top: 0 !important;
    }
  }
}