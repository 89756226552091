/*
    ==========================================================================
    #TEXT UTILITIES
    ==========================================================================
*/

/*
    Size utilities
*/

.u-text--bigger {
  // @include inuit-font-size($inuit-font-size-h3, $important: true);
  @include inuit-font-size(25px, $important: true);
}

.u-text--natural {
  @include inuit-font-size($inuit-global-font-size, $important: true);
}

.u-text--smaller {
  @include inuit-font-size($inuit-font-size-h5, $important: true);
}

.u-text--smallest {
  @include inuit-font-size($inuit-font-size-h6, $important: true);
}

.u-text--smallest2x {
  @include inuit-font-size($inuit-font-size-h6 - 2px, $important: true);
}

.u-text--cta {
  @include inuit-font-size($inuit-font-size-h6 + 1px, $important: true);
  text-transform: uppercase;
  font-weight: 700;
}

.u-text--huge {
  @include inuit-font-size($inuit-global-font-size * 11,
    $line-height: 'none',
    $important: true);
  line-height: 1;
  font-family: $global-heading-font-family;
  color: color-var(font, 3);
}

.has-normal-font-size {
  @include inuit-font-size($inuit-global-font-size,
    $line-height: 'none',
    $important: true);
}

.has-regular-font-size {
  @include inuit-font-size($inuit-global-font-size,
    $line-height: 'none',
    $important: true);
}

.has-small-font-size {
  @include inuit-font-size($inuit-font-size-h5,
    $line-height: 'none',
    $important: true);
}

.has-large-font-size {
  @include inuit-font-size($inuit-font-size-h1,
    $line-height: 'none',
    $important: true);
}

.has-larger-font-size {
  @include inuit-font-size($inuit-font-size-h0,
    $line-height: 'none',
    $important: true);
}

/*
    Weight utilities
*/

.u-text--strong {
  font-weight: $global-strong-font-weight !important;
}

.u-text--medium {
  font-weight: 500 !important;
}

.u-text--regular {
  font-weight: 400 !important;
}

.u-leading--bigger {
  line-height: 1.75 !important;
}

/*
    color utilities
*/

.u-text--success {
  color: color-var(success);
}

.u-text--warning {
  color: #FF7B00;
}

.u-text--error {
  color: color-var(error);
}

/*
    Transform utilities
*/

.u-text--uppercase {
  text-transform: uppercase;
}

.u-text--lowercase {
  text-transform: lowercase;
}

.u-text--capital {
  text-transform: unset;
}

/*
    Base Colors utilities
*/

@each $type,
$colors in $baseColors {
  $iter: 1;

  @each $color in $colors {
    .u-color--#{$type}-#{$iter} {
      background-color: $color !important;
    }

    .u-font--#{$type}-#{$iter},
    .u-font--#{$type}-#{$iter} *:not(.c-button),
    .has-text-color.has-#{$type}-#{$iter}-color,
    .has-text-color.has-#{$type}-#{$iter}-color a {
      color: $color !important;
    }

    $iter: $iter+1;
  }
}

/*
    Colors utilities
*/
/* stylelint-disable */
@each $type,
$colors in $useColors {
  $iter: 1;

  @each $color in $colors {
    .u-#{$type}-color--#{$iter} {
      @if($type==font) {
        color: var(--color-#{$type}-#{$iter}) !important;
      }

      @else if($type==bg) {
        background-color: var(--color-#{$type}-#{$iter}) !important;
      }

      @else if($type==border) {
        border-color: var(--color-#{$type}-#{$iter}) !important;
      }

      @else {
        color: var(--color-#{$type}-#{$iter}) !important;
      }
    }

    $iter: $iter+1;
  }
}

/* stylelint-enable */

.u-text--grayscale {
  color: color-var(font, 2) !important;
}

.u-text--colored {
  color: color-var(font, 5) !important;
}

.u-text--white {
  color: color-var(font, 3) !important;
}

.u-text--green {
  color: #198038 !important;
}

/*
    Align utilities
*/
@include responsiveClasses(u-text--left) {
  text-align: left !important;
}

@include responsiveClasses(u-text--center) {
  text-align: center !important;
}

@include responsiveClasses(u-text--right) {
  text-align: right !important;
}

.u-text--intend {
  padding-left: 30px !important;
}

/*

    State utilities

*/

.u-disabled {
  cursor: not-allowed !important;
}


.u-font--heading {
  font-family: $global-heading-font-family;
  font-weight: $global-heading-font-weight;
}

.u-font--body {
  font-family: $global-body-font-family;
}

.u-block {
  display: block;
}

.u-text--with-line {
  border-bottom: 1px solid color-var(border, 2);
  padding-bottom: $inuit-global-spacing-unit-big;
  margin-bottom: $inuit-global-spacing-unit-great;
}

.u-relative {
  position: relative;
}