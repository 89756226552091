.c-counter {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: $inuit-global-spacing-unit-large;
  }

  &__amount {
    position: relative;
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    max-height: 48px;
    max-width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    background: color-var(bg, 5);
    border-radius: 50%;
  }

  &__title {

  }
}