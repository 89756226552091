.c-post-related {
  padding-top: 32px !important;

  @include bp-down('medium') {
    &__title-main {
      justify-content: center;
    }

    // .c-post-list__item {
    //   flex: unset !important;
    // }
    .c-horizontal-nav--mobile {
      .u-margin-left {
        margin: 0 !important;
      }
    }

    &__heading {
      width: 100%;
      text-align: center;
    }

    .glide__slide {
      padding-left: 0;

      &.glide__slide--active {
        position: relative;
        z-index: 2;
      }
    }

    .glide__slides {
      margin-left: 0 !important;
    }

    .c-article__title {
      min-height: auto;
    }
  }

  .c-article__excerpt {
    margin-bottom: 0;
    white-space: normal;

    @include bp('medium') {
      margin-bottom: $inuit-global-spacing-unit;
    }
  }
}