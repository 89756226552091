.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: $global-border solid color-var(border, 2);
  // border-top: 0;
  background: color-var(bg);

  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: -4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.autocomplete-suggestion {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
  @include inuit-font-size($inuit-font-size-h5);
}

.autocomplete-suggestion b {
  font-weight: $global-strong-font-weight;
  color: color-var(font, 4);
}

.autocomplete-suggestion.selected {
  // background: color-var(bg, 5);
}
