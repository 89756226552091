.c-industries-alphabetical-section {
  &__tab-list {
    margin-top: $inuit-global-spacing-unit-huge;

    &-item {
      text-transform: uppercase;
    }
  }

  &__tab-content {
    padding-top: $inuit-global-spacing-unit-great;

    &-item {}

    &-letter {
      display: block;
      color: color-var(font, 1);
      font-weight: 700;
      letter-spacing: 0;
      text-transform: uppercase;
      margin-top: $inuit-global-spacing-unit-great;
      margin-bottom: $inuit-global-spacing-unit-great;
      @include inuit-font-size($inuit-font-size-h4, $inuit-line-height-h4);
    }

    &-link {
      @include inuit-font-size($inuit-font-size-h4, $inuit-line-height-h4);
      margin-bottom: $inuit-global-spacing-unit;
      display: block;
    }

    &-link-item {
      &+& {
        margin-top: $inuit-global-spacing-unit-big;
      }
    }
  }
}
