/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  margin-bottom: 2em;

  &__author {
    &-name {
      margin-bottom: 0;
    }

    &-img {
      width: $inuit-global-spacing-unit * 4;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__modified-date {
    margin-bottom: $inuit-global-spacing-unit-tiny !important;
  }

  &__modified-date,
  &__date {
    @include inuit-font-size($inuit-font-size-h6);
  }

  &__date {
    color: color-var(font, 2);
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__image {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__format {
    background: color-var(bg, 8);
    color: color-var(font, 3);
    border-radius: $global-radius;
    @include inuit-font-size($inuit-font-size-h6);
    padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-small;
    display: inline-block;
    margin-bottom: $inuit-global-spacing-unit-great;
    @include mq($until: 'large') {
      display: none;
    }

    &--alternative {
      background-color: color-var(bg, 1);
      color: color-var(font, 4);
      border: 2px solid color-var(border, 1);
    }
  }

  &__preview {
    @include inuit-font-size($inuit-font-size-h1);
  }

  &-related {
    &__wrapper {
      @include mq($from: large) {
        overflow: hidden;
      }
      @include mq($until: large) {
        position: relative;
      }
    }

    &__more {
      @include inuit-font-size($inuit-font-size-h5);
    }
  }
}

.c-post__content {
  @extend .block-editor-block-list__block;
  overflow: hidden;
  @include mq($until: 'large') {
    font-size: $inuit-global-font-size - 2px;
  }

  .c-post-list {
    padding-top: 0;
  }

  .alignnone {
    margin: 1em 1em 1em 0;
  }

  .aligncenter {
    margin: 1em auto;
  }

  .alignright {
    float: right;
    margin: 1em 0 1em 1em;
  }

  .alignleft {
    float: left;
    margin: 1em 1em 1em 0;
  }

  .wp-caption {
    text-align: center;
  }

  .wp-caption-text {
    font-style: italic;
    margin-top: 0.5em;
  }

  img {
    height: auto !important;
  }

  li {
    margin-bottom: 0;
    line-height: 2em;
  }
}
