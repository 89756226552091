/* ==========================================================================
   #LABEL
   ========================================================================== */

.c-label {
    display: block;
    margin-bottom: $inuit-global-spacing-unit-great;

    @include bp-down("medium") {
        &--select {
            .c-header & {
                display: none;
            }
        }
    }

    &__title {
        @include inuit-font-size($inuit-font-size-h5);
        display: inline-block;

        &:empty {
            display: none;
        }
    }

    &__desc {
        @include inuit-font-size($inuit-font-size-h6);
        line-height: 1.5em;
        margin-top: $inuit-global-spacing-unit-micro;
        margin-bottom: $inuit-global-spacing-unit-micro;
        display: block;
    }

    &__error {
        @include inuit-font-size($inuit-font-size-h6);
        padding-left: $inuit-global-spacing-unit-large;
        margin-top: 5px;
        color: color-var(error);
        @include icon-inside(true, func);
        display: none;
        align-items: center;
        line-height: 1.3;
        font-weight: 500;

        svg {
            width: 16px;
            height: 16px;
        }

        img {
            width: 16px;
            height: 16px;
            filter: none;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__asterisk {
        color: color-var(error);
    }

    &__container {
        margin-bottom: $inuit-global-spacing-unit-great;
    }

    &--disabled {
        pointer-events: none;

        &.c-label,
        .c-label__title {
            color: color-var(font, 2);
        }
    }

    &--inline {
        display: inline-block;
        margin-right: $inuit-global-spacing-unit-small;

        &-flex {
            display: flex;
            align-items: center;

            .c-label__title {
                color: color-var(font, 2);
                padding-right: $inuit-global-spacing-unit-small;
                @include inuit-font-size($inuit-font-size-h5);
            }

            .c-select__title {
                padding-top: $inuit-global-spacing-unit-tiny;
                padding-bottom: $inuit-global-spacing-unit-tiny;
            }
        }
    }

    &--toggleable {
        padding-bottom: $inuit-global-spacing-unit-micro;
        border-bottom: none;
    }

    &.is-toggled {
        display: none;
    }

    &--toggleable-arr {
        width: 100%;
        padding-right: $inuit-global-spacing-unit-great;
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: $inuit-global-spacing-unit-large;
            background: url("../assets/images/svg/rozwin.svg") no-repeat center right;
        }

        &.is-active {
            &:after {
                transform: rotateX(180deg);
            }
        }
    }

    &.has-error{
        .c-label__error{
            display: flex;
        }
        .c-label__title{
            color: color-var(error)
        }
        .c-input,.c-textarea{
            border-color: color-var(error);
        }
    }
}
