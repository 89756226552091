.c-search {
  $this: &;
  padding: 0 !important;

  &__advanced {
    position: relative;
    z-index: 1000;
  }

  &-filters {
    $this: &;
    padding-bottom: $inuit-global-spacing-unit-massive;
    padding-top: $inuit-global-spacing-unit-huge;
    padding-right: $inuit-global-spacing-unit-great;
    position: relative;

    @include bp-down('large') {
      display: none;

      &.is-active {
        display: block;
        position: fixed;
        top: $inuit-global-spacing-unit-big;
        left: $inuit-global-spacing-unit-big;
        right: $inuit-global-spacing-unit-big;
        z-index: 10002;
        bottom: $inuit-global-spacing-unit-big;
        overflow: auto;
        background: color-var(bg, 1);
        // padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-big 0;
        border: 1px solid color-var(border, 1);
        // border-radius: 5px;
        // box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.11);
      }
    }

    @include bp('large') {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -100vw;
        // box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
        // border-right: 1px solid rgba(0, 0, 0, 0.1);
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 0;
      }
    }

    &__heading {
      margin-bottom: 0;
      // text-transform: uppercase;
      // color: color-var(font, 2);
      font-weight: 400;
    }

    &__clear {
      text-decoration: underline !important;
      cursor: pointer;
    }

    &__title,
    &__meta,
    &__meta a {
      font-size: (14px / $inuit-global-font-size) * 1rem;
    }

    &__meta {
      margin-top: $inuit-global-spacing-unit-large;
      color: color-var(font, 2);
    }

    &__group {
      // margin-bottom: $inuit-global-spacing-unit-great;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:last-of-type {
        margin-bottom: 0;
      }

      &--bordered {
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
        border-radius: $global-radius;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }

      .is-full {
        display: none;
      }

      .is-empty {
        display: inline-block;
      }

      &.is-full {
        .is-full {
          display: inline-block;
        }

        .is-empty {
          display: none;
        }
      }
    }

    &__choosed {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    &__choosed,
    &__edit {
      background-color: transparent;
    }

    &__submit {
      margin-top: $inuit-global-spacing-unit-small;
      font-weight: bold;
    }

    &__overlay {
      position: sticky;
      bottom: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      background: linear-gradient(transparent -30%, white 80%);

      @include bp-down('medium') {
        display: flex;
        justify-content: space-between;
      }
    }

    .c-label {
      &__container {
        margin-bottom: 0;
      }
    }
  }

  &-results {
    $this: &;
    padding: 40px 0;

    &__sort,
    .c-select__title {
      min-width: 190px;
      max-width: 190px;
    }
  }

  &__overlay {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 9999;

    &.is-active {
      opacity: 0;
      @include fade-in;
      display: flex;
    }
  }
}

.c-filter-mobile {
  padding: 0;
  border: none;
  background: none;
  color: color-var(font, 3);
  cursor: pointer;
  @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h5);

  &:hover {
    span {
      text-decoration: none;
    }
  }

  svg,
  img {
    font-weight: bold;
    fill: color-var(font, 3);
    width: $inuit-global-spacing-unit-big;
    height: auto;
    margin-right: 1.25 * $inuit-global-spacing-unit-tiny;
  }

  span {
    text-decoration: underline;
  }
}

.c-close-popup {
  padding: 0;
  border: none;
  background: none;
  color: color-var(font, 3);
  cursor: pointer;
  width: 40px;
  position: fixed;
  top: $inuit-global-spacing-unit-big;
  right: $inuit-global-spacing-unit-big;
  $size: $inuit-global-spacing-unit-great;
  @include square-style($size);

  svg,
  img {
    font-weight: bold;
    fill: color-var(font, 3);
    width: $inuit-global-spacing-unit-big;
    height: auto;
    $size: $inuit-global-spacing-unit-small;
    @include square-style($size);
  }
}

.c-filter {
  &--opener {
    padding: 10px 16px;
    border: 1px solid var(--color-main-1);
    border-radius: 5px;
    background-color: #fff;
    width: 240px;
    padding: 16px 24px 16px 16px;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 9999;
    display: none;

    &.is-active {
      display: block;

      svg {
        transform: rotateZ(180deg);
      }
    }
  }

  &__search {
    background: none;
    border: 0;
    margin-left: -52px;
    margin-top: 2px;
    filter: brightness(0) saturate(100%) invert(25%) sepia(99%) saturate(7063%) hue-rotate(200deg) brightness(96%) contrast(100%);

    &-bar {
      padding: 5px 16px;
      margin-bottom: 0 !important;
      position: relative;
      width: auto;

      &::placeholder {
        font-size: 14/18*1rem;
        color: var(--color-main-1);
      }
    }
  }

  &s__sort {
    display: flex;
    gap: 12px;

    .c-label {
      margin-bottom: 0;
      margin-left: 0 !important;
    }

    .c-select__title {
      font-size: 14/18*1rem;
      color: color-var('main');
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  &s__list {
    gap: 12px;

    .c-button {
      margin: 0 !important;
      font-size: 14/18*1rem;
    }

    &.is-small {
      &>*:nth-child(n+5):not(:last-child):not(:nth-last-child(1)):not(.c-search__overlay) {
        display: none;
      }
    }

    &:not(.is-small) {
      &>*:nth-last-child(1) {
        display: none;
      }
    }
  }
}

.js-filteropen {
  &.is-active {
    svg {
      transform: rotateZ(180deg);
    }
  }
}

.relative {
  position: relative;
}