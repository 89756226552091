.c-calendar {
  &__empty {
    background: color-var(bg, 5);
    padding: $inuit-global-spacing-unit-huge;
    text-align: center;
    border-radius: $global-radius;
  }

  &__month {
    display: block;
    border-radius: $global-radius;
    text-align: center;
    padding: $inuit-global-spacing-unit-great;
    @include mq($until: large) {
      padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit;
    }
    border: 1px solid color-var(border, 2);
    margin-bottom: $inuit-global-spacing-unit-great;

    &--past {
      border-color: color-var(border, 5);

      .c-calendar__count {
        color: color-var(font, 2);
        background: color-var(bg, 5);
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__monthname {
    @include inuit-font-size($inuit-font-size-h1);
    @include mq($until: large) {
      @include inuit-font-size($inuit-font-size-h3);
    }
  }

  &__desc {
    @include inuit-font-size($inuit-font-size-h6);
  }

  &__count {
    @include square-style(2 * $inuit-global-spacing-unit-great);
    color: color-var(font, 3);
    background: color-var(bg, 2);
    @include inuit-font-size($inuit-font-size-h2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $inuit-global-spacing-unit-great;
    margin: $inuit-global-spacing-unit-great auto;
    @include mq($until: large) {
      margin: $inuit-global-spacing-unit auto;
    }
  }
  &--year {
    @include mq($until: large) {
      flex-wrap: wrap !important;
    }
  }
}
.fc-miniCal-button {
  display: none !important;
}
.post-type-archive-kalendarz .c-post-list .u-flex-11\/12 {
  @include mq($until: large) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.post-type-archive-kalendarz .c-post-list .o-flex--grids {
  @include mq($until: large) {
    flex-wrap: wrap !important;
  }
}
.fc-toolbar-title {
  @include mq($until: large) {
    @include inuit-font-size($inuit-font-size-h4, 1.1em, 0, true);
  }
}

.fc-event-title {
  text-overflow: ellipsis;
}

.fc-event-counter {
  @include mq($from: large) {
    display: none;
  }
}
