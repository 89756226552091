.c-table-section {
  &__tooltip {
    &-contaiter {
      position: absolute;
      top: $inuit-global-spacing-unit-micro;
      left: calc(100% - 24px);
      z-index: 2 ;
      display: flex;
    }

    &-heading {
      position: relative;
      padding-right: $inuit-global-spacing-unit-great;
    }

    &-icon {
      $size: $inuit-global-spacing-unit-huge - $inuit-global-spacing-unit-big ;
      @include square-style($size);
      background: none ;
      border: none ;
      border-radius: 50% ;
      padding: 0 ;
      margin: 0 ;
      position: relative;
      z-index: 2 ;
      cursor: pointer;
      overflow: hidden;
    }

    &-text {
      display: none;
      top: $inuit-global-spacing-unit-great;
      right: -100%;
      position: absolute;
      width: max-content;
      max-width: 345px;
      padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
      border: 1px solid #ececec;
      border-radius: 5px;
      background-color: color-var(bg, 1);
      box-shadow: 0 17px 33px 0 rgba(0, 0, 0, 0.24);
      letter-spacing: 0;
      z-index: 1;
      color: color-var(font, 1);
      @include inuit-font-size($inuit-font-size-h6,$inuit-line-height-h6);
      @include mq($from: 'large') {
        top: 50%;
        left: $inuit-global-spacing-unit-great;
        right: auto;
        transform: translate(0, -50%);
        max-width: 360px;
      }

      &.is-active {
        display: block ;
      }
    }

    &-close {
      position: absolute;
      top: $inuit-global-spacing-unit-small;
      right: $inuit-global-spacing-unit-small;
      padding: $inuit-global-spacing-unit-micro ;
      display: flex;
      margin: 0 ;
      z-index: 2 ;
      cursor: pointer;
      background: none ;
      border: none ;
      border-radius: 0 ;

      svg {
        $size: $inuit-global-spacing-unit-small ;
        @include square-style($size);
      }
    }
  }

  &__table {
    overflow-x: auto;
    text-align: center;
    border-top: 1px solid color-var(border, 2);

    &-row {
      letter-spacing: 0;
      color: color-var(font, 1);
      @include inuit-font-size($inuit-font-size-h4,$inuit-line-height-h4);
      border-bottom: 1px solid #D8D8D8 ;

      &:last-of-type {
        margin-bottom: $inuit-global-spacing-unit-large;
      }

      &-th {
        padding-top: $inuit-global-spacing-unit-large ;
        padding-bottom: $inuit-global-spacing-unit-large ;
      }

      &-td {
        padding-top: $inuit-global-spacing-unit-small ;
        padding-bottom: $inuit-global-spacing-unit-small ;
        flex-wrap: wrap;

        span {
          display: block;
          width: 100%;
        }

        &--green {
          letter-spacing: 0;
          color: #009f3b;
          @include inuit-font-size($inuit-font-size-h5,$inuit-line-height-h5);
        }

        &--red {
          letter-spacing: 0;
          color: color-var(font, 5);
          @include inuit-font-size($inuit-font-size-h5,$inuit-line-height-h5);
        }
      }
    }

    &-caption {
      letter-spacing: 0;
      color: color-var(font, 1);
      @include inuit-font-size($inuit-font-size-h6,$inuit-line-height-h6);
      margin-bottom: $inuit-global-spacing-unit-large;
    }
  }
}
