/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-burger {
    border: none;
    background: none;
    // color: color-var(font, 4);
    padding: 0;
    border-radius: 0;
    cursor: pointer;
    position: relative;
    z-index: 11;
    font-weight: 500;
    color: color-var(font, 1);
    margin: 0;
    @include inuit-font-size($inuit-font-size-h6);
    &--collapse {
        color: color-var(font, 4);
        .c-burger__menu {
            span {
                &:nth-child(1) {
                    transform: rotate(-45deg);
                    top: 5px;
                    @include bp-between('medium', 'large') {
                      top: 7px;
                    }
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }
    &__menu {
        display: block;
        position: relative;
        width: 14px;
        height: 14px;
        margin-right: $inuit-global-spacing-unit-micro;
        span {
            background: color-var(bg, 2);
            display: block;
            height: 3px;
            border-radius: 1px;
            position: relative;
            &:not(last-child) {
                margin-bottom: 2px;
                @include bp-between('medium', 'large') {
                    margin-bottom: 4px;
                }
            }
        }
        @include bp-between('medium', 'large') {
            width: 24px;
            height: 24px;
        }
    }
}
