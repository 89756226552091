.c-wcag-menu {
  width: 100%;
  height: ($inuit-global-spacing-unit-large * 2) +
    ($inuit-global-font-size * 1.125);
  position: absolute;
  top: -(($inuit-global-spacing-unit-large * 2) +
        ($inuit-global-font-size * 1.125));
  left: 0;
  right: 0;
  z-index: 100;

  @include mq($until: large) {
    height: 100px;
    top: -100px;

    &__item {
      min-height: 100px;
      max-height: 100px;
      white-space: nowrap;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  &__item {
    position: absolute;
  }

  &__link {
    font-size: $inuit-global-font-size * 1.125;
    line-height: 1;
    background-color: color-var(main, 1);
    color: color-var(func, 1);
    border-radius: $global-radius;
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-great *
      2;
    display: inline-block;

    &:hover {
      color: color-var(func, 1);
    }

    &:focus {
      transform: translateY(100%);
      outline: none;
    }
  }
}
