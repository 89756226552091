/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;
  z-index: 1000;
  background: color-var(bg);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  // @include bp('large') {
  //   background: color-var(bg, 3);
  //   background: -moz-linear-gradient(90deg,
  //       color-var(bg, 3) 0%,
  //       color-var(bg, 3) 49%,
  //       color-var(bg) 50%,
  //       color-var(bg) 100%);
  //   background: -webkit-linear-gradient(90deg,
  //       color-var(bg, 3) 0%,
  //       color-var(bg, 3) 49%,
  //       color-var(bg) 50%,
  //       color-var(bg) 100%);
  //   background: linear-gradient(90deg,
  //       color-var(bg, 3) 0%,
  //       color-var(bg, 3) 49%,
  //       color-var(bg) 50%,
  //       color-var(bg) 100%);
  //   filter: progid:dximagetransform.microsoft.gradient(startColorstr='#D11632', endColorstr='#ffffff', GradientType=1);
  // }

  @include bp-down('large') {
    & {
      background-color: color-var(bg, 1);
    }

    &--mobile-open,
    &--search-open {
      z-index: 2001;

      .c-header__top {
        background-color: color-var(bg, 1);
        box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.11);
      }
    }
  }

  &__wrapper {
    position: relative;

    @include bp-down('large') {
      .o-flex {
        flex-wrap: nowrap;
        align-items: center;
      }
    }
  }

  &__top {
    // min-height: 60px;

    // @include bp('large') {
    //   min-height: 104px;
    // }

    // @include mq($until: 'large') {
    //   box-shadow: $global-shadow;
    // }
  }

  &__logo {
    height: auto;
    filter: none !important;

    @include bp-down('large') {
      width: auto;
      height: 40px;
    }

    @include mq($from: 'large') {
      min-height: 104px;
      padding: $inuit-global-spacing-unit-small 0;
    }

    &-wrapper {
      position: relative;
      background: color-var(bg, 3);

      @include bp-down('large') {
        width: 45px !important;
        min-width: 45px;
        max-width: 45px;
        flex: auto !important;
        padding: 1.25 * $inuit-global-spacing-unit-tiny 1.5 * $inuit-global-spacing-unit-micro !important;
      }

      @include bp('large') {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 100%;
          width: $inuit-global-spacing-unit-big;
          height: 100%;
          background: color-var(bg, 3);
        }
      }

      @include bp-between('medium', 'large') {
        margin-left: 30%;
      }
    }
  }

  &__webtitle {
    white-space: nowrap;
    padding: 0 36px 0 15px;

    .c-heading {
      margin-bottom: 0;

      @include bp-down('large') {
        @include inuit-font-size($inuit-font-size-h4);
      }
    }
  }

  &__content {
    // background: color-var(bg);

    @include bp-down('large') {
      width: auto !important;
      padding-left: $inuit-global-spacing-unit-micro !important;
      flex: auto !important;
    }
    width: 100%;
    .last{
      border-left: 1px solid #E6E6E6;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 28px;
      cursor:pointer;
      &:hover svg{
        filter: brightness(0) saturate(100%) invert(25%) sepia(99%) saturate(7063%) hue-rotate(200deg) brightness(96%) contrast(100%);
      }
    }
    .c-search--icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__nav {
    background: color-var(bg, 5);
  }

  .t-transparent-header & {
    background: transparent;
    color: color-var(font, 3);
  }

  &__highlighted {
    padding: 7px 14px;
    background-color: color-var(bg, 2);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 26px;

    .c-icon {
      width: 26px;
      height: 26px;
      position: static;
      margin-right: 8px;
    }

    text-decoration: underline;

    &:hover {
      color: color-var(font, 3);
      opacity: 0.8;
    }
  }

  &__pre {
    background: color-var(bg, 8);
    color: color-var(font, 3);
    @include inuit-font-size($inuit-font-size-h6 - 2px);
    *>div:last-child{
      min-height: 40px;
    }
    // padding: $inuit-global-spacing-unit-tiny - 2px 0;

    &-text {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: $inuit-global-spacing-unit-small;
      }
    }
  }

  &__icon {
    margin-right: $inuit-global-spacing-unit-great !important;

    &-zoom,
    &-contrast {
      height: 24px;
      display: block;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__search {
    @include bp-down('large') {
      .c-header & {
        padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-big;
        background: color-var(bg, 1);
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        z-index: -1;
        max-width: none !important;
      }
    }

    .c-button:hover {
      color: color-var(font, 3);
    }

    .c-label--select {
      margin-left: 0 !important;
    }

    
    width: 50vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 9px 0;

  }

  .is-only-in-footer {
    display: none;
  }

  &__mobile {
    padding-top: $inuit-global-spacing-unit-great;
    width: 300px;
    position: absolute;
    z-index: -1;
    top: 60px;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.11);
    background: color-var(bg, 1);

    @include bp('large') {
      display: none;
    }

    &-buttons {
      margin-top: $inuit-global-spacing-unit-large;
      margin-bottom: $inuit-global-spacing-unit-large;

      .c-button {
        margin-bottom: $inuit-global-spacing-unit-large;
      }

      .o-link {
        @include inuit-font-size($inuit-font-size-h6);
      }
    }

    &-burger {
      width: 100px !important;
      min-width: 100px;
      max-width: 100px;
      flex: auto !important;
    }

    &-pre {
      padding: $inuit-global-spacing-unit-big;

      svg {
        min-width: $inuit-global-spacing-unit-large;
      }
    }
  }

  &__search-mobile {
    border: none;
    background: none;
    color: color-var(font, 1);
    padding: 0;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    font-weight: 500;
    @include inuit-font-size($inuit-font-size-h6);

    &--collapse {
      color: color-var(font, 4);

      .c-header__search-mobile-icon--search {
        display: none;
      }

      .c-header__search-mobile-icon--close {
        display: inline-flex;
      }
    }

    &-icon {
      width: $inuit-global-spacing-unit;
      height: auto;
      display: inline-flex;
      margin-right: $inuit-global-spacing-unit-micro;

      &--search {
        display: inline-flex;
      }

      &--close {
        display: none;
      }

      svg {
        * {
          fill: color-var(font, 4);
        }
      }

      @include bp-between('medium', 'large') {
        width: $inuit-global-spacing-unit-large;

        svg {
          width: $inuit-global-spacing-unit-large;
          height: auto;
        }
      }
    }
  }
}

@include bp-down('large') {
  .mobile-overlay {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.7;
    background-color: color-var(bg, 8);
    z-index: 9;

    @include mq($until: large) {
      z-index: 2000;
    }
  }
}