.c-context-menu {
	padding: 1.25*$inuit-global-spacing-unit-tiny 1.25*$inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-micro 1.25*$inuit-global-spacing-unit-tiny;
	@include bp('large') {
		@include inuit-font-size($inuit-font-size-h5);
		border: 1px solid color-var(border);
		border-radius: $global-radius;
		padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-big;
	}
	&__item {
		margin-bottom: 1em;
		position: relative;
		cursor: pointer;
		@include bp-down('large') {
			position: relative;
			padding-left: 1.1*$inuit-global-spacing-unit-big;
			margin-bottom: $inuit-global-spacing-unit-small;
		}
		&::before {
			content: "";
			display: none;
			width: 10px;
			height: 100%;
			left: -$inuit-global-spacing-unit-big;
			background: color-var(bg, 5);
			top: 0;
			position: absolute;
			@include bp-down('large') {
				left: -1.25*$inuit-global-spacing-unit-tiny;
			}
		}

		&.is-active {
			font-weight: 700;
		}

		&.is-active,
		&:hover {
			&:before {
				display: block;
			}
		}
	}
	@include bp-down('large') {
		&__link {
			@include inuit-font-size($inuit-font-size-h6, $inuit-line-height-h6);
		}
	}
	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: $inuit-global-spacing-unit-micro;
		svg {
			width: 20px;
			height: auto;
			fill: color-var(font, 4);
		}
	}
	&__show-more {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0 $inuit-global-spacing-unit-small 0 0;
		z-index: 2;
		background: none;
		border-radius: 0 ;
		border: none ;
		cursor: pointer ;
		width: 100%;
		height: $inuit-global-spacing-unit-huge ;
		.c-icon  {
			position: static;
			left: auto;
			display: flex;
		}
	}
}
