.u-shadow {
  $this: &;

  &, &--regular {
    box-shadow: 0 13px 25px 0 rgba(0,0,0,0.15);
  }

  &--light {
    box-shadow: 0 7px 15px 0 rgba(0,0,0,0.7);
  }

  &--strong {
    box-shadow: 0 13px 25px 0 rgba(0,0,0,0.3);
  }
}