/* ==========================================================================
   #SWITCH BUTTON
   ========================================================================== */

.c-switch {
  @include clickableInput();
  $switchsize: ($inuit-global-spacing-unit-tiny + 2 * $global-border) * 2;
  $switchposition: ($switchsize / 4) + $global-border;
  height: $switchsize;
  @include transition();

  &__input {
    @include inuit-hidden-visually();
  }

  &:hover {
    .c-switch {
      &__replacement {
        &:before {
          border-color: #737373;
          color: #737373;
        }
      }
    }
  }

  &__replacement {
    width: $inuit-global-spacing-unit-great;
    height: $switchsize/2;
    border-radius: $switchsize/4;
    top: $switchposition;
    background: #737373;
    border-color: #737373;

    &:before {
      @include absolutePseudoContent();
      @include square-style($switchsize);
      border-radius: 50%;
      left: -1px;
      top: $switchposition * -1;
      background: #fff;
      border: 1px solid #737373;
    }
  }

  input:checked {
    &+.c-switch__replacement {
      background: color-var(main);
      border-color: color-var(main);

      &:before {
        left: auto;
        right: -1px;
        background: color-var(bg);
      }
    }
  }
}
