.c-remove-btn,
.c-edit-btn {
    color: var(--color-font-3) !important;
    background: var(--color-error-1);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    text-decoration: none !important;
    font-size: 20px;
    font-size: 1.11111rem;
    line-height: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 32px;
}

.c-edit-btn {
    background: #EED202;
}

.c-remove-btn {
    flex: 0 0 32px;
    margin-bottom: 64px;
}

.c-label-input__container {
    display: flex;
    align-items: flex-end;
}

.c-label-input__container .c-label {
    flex: 1;
}