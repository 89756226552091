/* stylelint-disable max-nesting-depth */
.c-event {
  @include inuit-font-size($inuit-font-size-h6);
  display: block;

  @include mq($until: medium) {
    border-radius: 5px;
    background-color: color_var(bg, 1);
  }

  &:not(.fc-event-main-frame) {
    white-space: initial;
  }

  @include mq($from: medium) {
    &:hover .c-event__content {
      background-color: color_var(bg, 2);
      color: color_var(font, 3);

      .c-article__time,
      .c-article__place {
        color: color_var(font, 3);
      }
    }

    &:hover {
      .fc-daygrid-event {
        background-color: color_var(bg, 2) !important;
        color: color_var(font, 3) !important;
      }
    }
  }

  &__title {
    font-weight: 500;
    margin-bottom: $inuit-global-spacing-unit-micro;

    @include mq($until: medium) {
      color: color-var(font, 4);
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }
  }

  &__content {
    padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-tiny;

    @include mq($until: medium) {
      padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit $inuit-global-spacing-unit;
    }
  }

  .c-article {
    &__place,
    &__time {
      color: color-var(font);

      @include mq($until: medium) {
        display: none;
      }
    }

    &__category {
      color: color-var(font, 4);
      background-color: color_var(bg, 1);
      font-size: 12px;
      line-height: 1;
      padding: $inuit-global-spacing-unit-tiny;
      text-align: center;

      @include mq($until: medium) {
        border: 1px solid color_var(border, 1);
        border-radius: 5px;
        color: color_var(font, 4);
        font-size: 0.75rem;
        line-height: 1;
        padding: $inuit-global-spacing-unit-tiny;
        margin-top: $inuit-global-spacing-unit;
        margin-left: $inuit-global-spacing-unit;
        display: inline-block;
      }
    }
  }

  &:hover {
    .c-event__popup {
      display: block;
    }
  }

  &__popup {
    white-space: normal;
    position: absolute;

    .fc-scrollgrid-sync-table tbody > tr:nth-child(1) &,
    .fc-scrollgrid-sync-table tbody > tr:nth-child(2) &,
    .fc-scrollgrid-sync-table tbody > tr:nth-child(3) & {
      top: 102%;
    }

    .fc-scrollgrid-sync-table tbody > tr:nth-child(4) &,
    .fc-scrollgrid-sync-table tbody > tr:nth-child(5) &,
    .fc-scrollgrid-sync-table tbody > tr:nth-child(6) &,
    .fc-scrollgrid-sync-table tbody > tr:nth-child(7) & {
      bottom: 102%;
    }

    .fc-day-mon &,
    .fc-day-tue &,
    .fc-day-wed & {
      left: 0;
    }

    .fc-day-thu &,
    .fc-day-fri &,
    .fc-day-sat &,
    .fc-day-sun & {
      right: 0;
    }
    width: 370px;
    z-index: 100;
    background: color-var(bg);
    border: 1px solid color-var(border, 2);
    padding: $inuit-global-spacing-unit;
    display: none;

    .c-event__title {
      @include inuit-font-size($inuit-font-size-h5);
      margin-bottom: $inuit-global-spacing-unit-micro;
    }

    .c-article {
      &__excerpt {
        min-height: 1px;
        margin: 0 0 $inuit-global-spacing-unit-small !important;
        color: color-var(font);
      }

      &__label {
        margin-left: auto;
      }
    }
    @include mq($until: large) {
      display: block !important;
      position: static;
      width: auto;
      border: 0;
      padding: 0;

      & > *:not(.u-text--right) {
        display: none !important;
      }
    }
  }

  &s__wrapper {
    overflow: visible !important;
  }

  &-list {
    &__item {
      &-icon {
        svg {
          // fill: color-var(func, 3);
        }
      }
    }
  }

  &-meta {
    border: 1px solid color_var(border, 2);
    border-radius: 10px;
    padding: $inuit-global-spacing-unit-large;
    margin-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    display: flex;
    flex-wrap: wrap;
    gap: $inuit-global-spacing-unit-large;

    &__item {
      width: 100%;

      @include bp(medium) {
        width: calc(50% - 12px);
      }
    }

    &__name {
      @include inuit-font-size($inuit-font-size-h5, 1.5);
      color: color-var(sub, 2);
    }

    &__content {
      @include inuit-font-size($inuit-font-size-h3, 32/20);
      color: color-var(sub, 2);

      &--bold {
        font-weight: bold;
      }

      .c-event-meta__item--green & {
        color: #198038;
      }
    }

    &__icon {
      margin-right: $inuit-global-spacing-unit;
      color: color-var(font, 4);

      svg {
        width: 32px;
        height: 32px;
      }

      .c-event-meta__item--green & {
        color: #198038;
      }
    }

    &__btn {
      @include mq($from: medium) {
        padding-left: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;
      }
    }
  }

  &__icon {
    margin-right: $inuit-global-spacing-unit-micro;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__date {
    display: none;

    @include mq($until: medium) {
      display: flex;

      &-content, &-content--full {
        background: color-var(bg, 5);
        width: 50%;
        padding: $inuit-global-spacing-unit-tiny;
        @include inuit-font-size($inuit-font-size-h6, 24/14);
        font-weight: bold;
      }

      &-content--full {
        width: 100%;
      }

      &-footer {
        color: color_var(font, 3);
        background-color: color_var(bg, 3);
        width: 50%;
        padding: $inuit-global-spacing-unit-tiny;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        @include inuit-font-size($inuit-font-size-h6, 24/14);

        span, strong {
          display: block;
        }
      }
    }
  }

  &__eventtime {
    font-weight: normal;
  }

  @include mq($until: medium) {
    &__popup {
      padding: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit;
      // display: flex !important;
      // justify-content: space-between;
      // align-items: center;
    }

    &__popup .c-article__place {
      display: flex !important;
    }

    &__popup-footer {
      color: color-var(font, 4);
      font-weight: bold !important;
      text-decoration: underline;
      @include inuit-font-size($inuit-font-size-h5, 24/14);
      display: inline-block;
    }
  }
}
