.c-login {
  $this: &;

  &__grids {
    @include bp-down("large") {
      display: flex;
    }
  }

  &__form-column {
    @include bp-down("large") {
      display: flex;
      order: -1;
      flex-direction: column;
      margin-top: 0 !important;
    }
  }

  &__content {
    @include bp("large") {
      margin-top: $inuit-global-spacing-unit-huge;
      padding-right: $inuit-global-spacing-unit-massive;
    }
  }

  &__validation {
    margin-bottom: $inuit-global-spacing-unit-large;
    margin-top: $inuit-global-spacing-unit-small;
  }

  &__condition {
    @include inuit-font-size($inuit-font-size-h5);
    margin: $inuit-global-spacing-unit-small 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;

    svg {
      display: none;
    }

    &[data-valid="0"] {
      svg:nth-of-type(1) {
        display: inline;
      }
    }
    &[data-valid="1"] {
      svg:nth-of-type(2) {
        display: inline;
      }
    }
  }
}
