h1,
h2,
h3,
h4,
h5,
h6 {
  &.block-editor-block-list__block {
    position: relative;
    font-weight: 500;
    margin-bottom: $inuit-global-spacing-unit;
    z-index: 1;
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
  }
}

.wp-block {
  margin-top: 0;
  margin-bottom: 0;

  &-post-title {
    @include mq($until: 'large') {
      font-size: $inuit-font-size-h1 !important;
    }
  }
}

.block-editor-block-list__layout,
.block-editor-block-list__block {
  &[data-type^='acf'] {
    // margin-top: 0;
    // margin-bottom: 0;
  }

  &:not([data-type^='acf']) {
    .wp-block-quote {
      border: $global-border solid color-var(border);
      padding: $inuit-global-spacing-unit-large;
      line-height: 2em;
      color: color-var(font, 2);
      font-style: normal;

      &.is-style-large {
        border: 0;
        padding: 0;
        padding-left: $inuit-global-spacing-unit-massive;
        background: url(../assets/images/svg/quote.svg) no-repeat top
          $inuit-global-spacing-unit-tiny left;

        p {
          color: color-var(font);
          font-family: $global-heading-font-family;
          @include inuit-font-size(
            $inuit-font-size-h3,
            $inuit-font-size-h4 * 2
          );
        }

        .wp-block-quote__citation,
        cite {
          display: block;
          text-align: right;
          font-style: normal;
          color: color-var(font, 1);
          @include inuit-font-size($inuit-font-size-h5);
          margin-top: $inuit-global-spacing-unit;

          &:before {
            content: '- ';
          }
        }
      }

      p {
        margin: 0;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(.wp-block-post-title):not(.c-heading) {
        position: relative;
        font-weight: 500;
        margin-bottom: $inuit-global-spacing-unit;
        z-index: 1;
        @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
      }
    }

    img {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    a:not([class]) {
      @extend .o-link;
      @extend .o-link--visible;
    }

    ul:not([class]),
    ol:not([class]),
    ul.block-editor-block-list__block,
    ol.block-editor-block-list__block {
      padding-left: $inuit-global-spacing-unit-great;
      list-style: none;
      counter-reset: list;
      margin: 0;
      margin-bottom: $inuit-global-spacing-unit-great;
      line-height: 2.25em;

      li {
        position: relative;
      }

      li:before {
        counter-increment: list;
        content: counter(list) '.';
        position: absolute;
        left: -$inuit-global-spacing-unit-great;
        width: $inuit-global-spacing-unit-great;
        text-align: center;
      }
    }

    ul:not([class]) li:before,
    ul.block-editor-block-list__block li:before {
      content: '•';
      font-size: 1.5em;
      line-height: 1.5em;
    }

    p:not([class]),
    p.block-editor-block-list__block {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    .post-type-post & {
      max-width: 670px;
    }

    &-gallery {
      .blocks-gallery-grid {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
      }

      @for $i from 1 through 8 {
        &.columns-#{$i} {
          .blocks-gallery-item {
            flex: 0 0 calc(100% / #{$i});
          }
        }
      }
    }
  }

  .wp-block-post-title {
    position: relative;
    font-weight: 400;
    font-family: $global-heading-font-family !important;
    @include inuit-font-size($inuit-font-size-h0);
    margin-bottom: $inuit-global-spacing-unit-great !important;
  }

  .wp-block-post-featured-image {
    margin-bottom: $inuit-global-spacing-unit-great;

    img {
      margin-bottom: 0;
      max-width: 100% !important;
      height: auto;
    }
  }
}

.wp-block-post-featured-image {
  img {
    max-width: 100% !important;
    height: auto !important;
  }
}

.wp-block-image {
  img {
    height: auto;
  }
}
