.c-gallery {
  &__item {
    img {
      cursor: pointer;
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2001;
    display: none;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      background: color-var(bg, 8);
      opacity: 0.7;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &.is-active {
      display: flex;
      @include fade-in;
    }
  }

  &__container {
    background: color-var(bg, 1);
    border-radius: $global-radius;
    width: 100vw;
    padding: $inuit-global-spacing-unit-big * 2.5 $inuit-global-spacing-unit-big;
    position: relative;

    @include bp(medium) {
      width: 70vw;
      padding: $inuit-global-spacing-unit-big * 2.5;
    }
  }

  &__current-img {
    max-width: 100%;
    max-height: 70vh;
    margin: 0 auto;
    display: block;
  }

  &__close {
    width: $inuit-global-baseline * 8;
    height: $inuit-global-baseline * 8;
    padding: $inuit-global-spacing-unit-tiny;
    position: absolute;
    top: $inuit-global-baseline * 3;
    right: $inuit-global-baseline * 3;
    display: inline-block;

    &::before, &::after {
      content: '';
      background-color: color-var(bg, 8);
      width: $inuit-global-baseline * 4;
      height: $inuit-global-baseline / 2;
      border-radius: $global-radius;
      position: absolute;
      top: $inuit-global-baseline * 3;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;

    &-list {
      list-style-type: none;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
    }

    li {
      margin: 0;
    }
  }

  &__link {
    color: color-var(font, 1);
    border: 1px solid #8f8f8f;
    border-radius: $global-radius;
    font-size: $inuit-global-font-size;
    line-height: $inuit-global-font-size;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
    margin: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-tiny 0;
    display: inline-block;

    &:hover, &.is-active {
      background-color: color-var(bg, 3);
      color: color-var(font, 3);
      border-color: color-var(border, 3);
    }
  }

  &__prev, &__next {
    width: $inuit-global-font-size + $inuit-global-spacing-unit-small * 2;
    height: $inuit-global-font-size + $inuit-global-spacing-unit-small * 2;
    position: relative;

    &::before {
      content: '';
      border-left: 2px solid color-var(border, 6);
      border-bottom: 2px solid color-var(border, 6);
      width: $inuit-global-baseline * 3;
      height: $inuit-global-baseline * 3;
      position: absolute;
      transform: translate(0, $inuit-global-baseline / 2) rotate(45deg);
    }

    &:hover::before {
      border-color: color-var(border, 4);
    }
  }

  &__next {
    &::before {
      transform: translate(-$inuit-global-baseline, $inuit-global-baseline / 2) rotate(-135deg);
    }
  }
}
