/* ==========================================================================
   #INPUT
   ========================================================================== */

.c-input {
  @include formInput();

  &[type='password'] {
    letter-spacing: 8px;
  }

  &--alternative {
    border-color: color-var(font, 2);
    transition: $global-transition;

    &:focus {
      border-color: color-var(border, 2);
    }
  }

  &--number {
    &::-webkit-inner-spin-button {
      display: none;
      pointer-events: none;
    }

    /* stylelint-disable */
    -moz-appearance: textfield;
    /* stylelint-enable */
  }
  /* stylelint-disable */
  &#ks_start_date,
  &#ks_end_date,
  &--date {
    width: 120px;
    // background: color-var(bg) url('../assets/images/svg/calendar.svg') no-repeat center right $inuit-global-spacing-unit;
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-datetime-edit {
      padding: 0;
    }

    &::-webkit-datetime-edit-fields-wrapper {
      padding: 0;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
      pointer-events: none;
    }

    &::-ms-clear {
      display: none;
      pointer-events: none;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0 !important;
      height: $inuit-font-size-h4;
      cursor: pointer;

      @include mq($from: medium) {
        pointer-events: none;
      }
    }
  }
  /* stylelint-enable */

  &--password {
    padding-right: $inuit-global-spacing-unit-huge;

    &:not([type='password']) + .c-input__password-eye {
      background-image: url('../assets/images/svg/password_see.svg');
    }
  }

  &--with-prefix {
    padding-left: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit +
      $inuit-global-spacing-unit-small;
  }

  &--with-suffix {
    padding-right: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit +
      $inuit-global-spacing-unit-small;
  }

  &--file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  &__file-edit-cta {
    display: none;
  }

  &__container {
    position: relative;
    display: block;

    &--file {
      background: color-var(bg, 5);
      padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
      @include inuit-font-size($inuit-font-size-h5);
      color: color-var(font, 2);
      margin-top: $inuit-global-spacing-unit-big;

      &.is-dragover {
        outline: 2px dashed color_var(border, 2);
      }

      .c-label--error & {
        border-color: color-var(error);
      }

      .c-label--disabled & {
        opacity: 0.5;
      }
    }

    &--file.is-chosen {
      display: none;
    }
  }

  &__password-eye {
    position: absolute;
    right: $inuit-global-spacing-unit;
    top: 0;
    bottom: 0;
    margin: auto;
    width: $inuit-global-spacing-unit-large;
    height: $inuit-global-spacing-unit-large;
    background: url('../assets/images/svg/password.svg') no-repeat center center;
    cursor: pointer;
  }

  &__prefix,
  &__suffix {
    position: absolute;
    top: 0;
    color: color-var(font);
    @include inuit-font-size($inuit-font-size-h4);
    line-height: 1.4em;
    font-weight: $global-strong-font-weight;
    padding: $inuit-global-spacing-unit-small;
    border-top: $global-border solid transparent;
    border-bottom: $global-border solid transparent;
    background: color-var(bg, 5);
    border: 1px solid color-var(border, 2);
  }

  &__prefix {
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__suffix {
    right: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__calendar {
    position: relative;
    z-index: 1;
  }

  &__date--container {
    position: relative;
    z-index: 100;
    @include mq($until: medium) {
      position: static;
    }
  }
}
