body.home {
    .o-layout--with-sidebar>.o-wrapper>div {
        display: flex;
        flex-wrap: wrap;

        .o-layout__sidebar {
            order: -1;
        }
    }

    // .c-article__date-footer{
    //     background: transparent!important;
    //     padding: 0!important;
    //     color: #000!important;
    //     display: block!important;
    //     text-align: left;
    // }
}

blockquote[style] {
    padding: 24px;
    font-style: normal;

    p:before {
        display: none;
    }

    .c-buttons {
        margin-bottom: 0;
    }

    margin: -24px 12px 0;
    line-height: 1.6em;
}

.c-article-company__industry {
    color: #0063C1 !important;
}

.c-link-box svg {
    width: 16px;
}

.c-search-filters__clear {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;

    svg {
        margin-left: 6px !important;
    }
}

.c-card .c-search-filters__group {
    margin-bottom: 30px;
}

.c-banner--white .c-banner__icon {
    filter: none;
}

.c-banner--white img[src*='.svg'] {
    filter: brightness(0) saturate(100%) invert(28%) sepia(69%) saturate(7499%) hue-rotate(198deg) brightness(92%) contrast(101%);
}

.u-footer__poland-img {
    margin-left: 0 !important;
}

.c-search-filters {
    padding-bottom: 0;
}

.c-post__format {
    background: #0063C1;
    padding: 5px 6px;
    font-size: 11px;
    line-height: 1em;
    margin-top: 24px;
    margin-bottom: 10px;

    &+.wp-block-post-title {
        margin-bottom: 10px !important;
    }
}

.c-post__modified-date {
    margin-bottom: 24px !important;
}

.c-link-box__title {
    padding-left: 12px;
    padding-right: 12px;
}

.c-filter--opener {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    .c-label__title {
        font-size: 14px;
    }
}

// .c-card__form .c-card{
//     display:block!important;
// }

body.home .c-article__date-footer,
body.home .c-article__date-small {
    font-size: 10px !important;
    color: #737373 !important;
    border: 1px solid #E6E6E6 !important;
    padding: 4px 3px !important;
    border-radius: 0 !important;
    background: 0 0 !important;
    text-align: left !important;
    line-height: 1em !important;
    max-width: fit-content;
    display: inline-block;
    border-radius: 4px !important;
    width: auto;
    display: inline-block !important;
}

body.home .c-article__date-footer.open {
    color: color-var(success, 1) !important;
}

body.home .c-article__date-footer.closed {
    color: color-var(error, 1) !important;
}

.c-article__date-footer+.c-article__title {
    color: color-var(main, 1) !important;
}

body.home .c-article__desc {
    padding-right: 0;

    .c-article__content {
        display: block;
    }
}

.c-post-related__wrapper {
    overflow: visible !important;
}

.c-article__date-footer,
.c-article__date_small {
    background: #fff !important;
    border-radius: 5px !important;
    color: #737373 !important;
    font-size: 12px !important;
    padding: 8px !important;
    border: 1px solid #E6E6E6 !important;
    line-height: 1em !important;
    display: flex !important;
    align-items: center !important;
    margin-right: 14px !important;
    height: auto !important;
    white-space: nowrap !important;
}

.c-lang {
    &-list {
        li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .c-close {
                color: #0063C1;
                border: 2px solid #0063C1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 21px;
                height: 21px;
                border-radius: 11px;
                margin-left: 10px;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }

    &-input {
        width: 100%;
    }
}

.c-inputs__group {
    position: relative;
    margin-bottom: 30px;

    .c-input__container:not(.c-input__limitter) {
        position: absolute;
        left: 0;
        top: 115px;
        width: 100%;
    }

    .c-label {
        margin-bottom: 0;
    }

    .c-filelist:not(:empty) {
        margin-top: 32px;
    }

    &+.c-inputs__group {
        .c-input__container:not(.c-input__limitter) {
            top: 73px;
        }
    }
}

.c-filelist {
    .c-file {
        width: 100%;
        margin-bottom: 6px;

        &__icon {
            width: 32px;
        }

        &__data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-left: 6px;
        }

        &__desc {
            display: none;
        }

        &__remove {
            position: static;
            top: 0;
            left: 0;
            color: #0063C1 !important;
            border: 2px solid #0063C1;
            width: 21px;
            height: 21px;
            border-radius: 11px;
            background: transparent;
            line-height: 1em;
            font-size: 1.1em;
            padding-bottom: 1px;

        }
    }
}

.c-input__container.c-input__container--file {
    border-radius: 5px;
    border: 1px dashed #0063C1;
    background: #F2F2F2;
    text-align: center;
    padding: 16px;
}

@media (max-width:1024px) {
    .c-header__mobile-burger {
        width: 60px !important;
        height: 60px !important;
        min-width: 60px !important;
        min-height: 60px !important;
        max-width: 60px !important;
        max-height: 60px !important;
        align-items: center;
        justify-content: center;
        display: flex;

        .c-burger__menu {
            margin-right: 0;
        }
    }

    .c-header-logo {
        width: 60px !important;
        height: 60px !important;
        min-width: 60px !important;
        min-height: 60px !important;
        max-width: 60px !important;
        max-height: 60px !important;
        flex: 0 0 60px !important;
    }

    .c-header__content .last {
        padding: 18px !important;
    }

    .c-header__content {
        padding-left: 0;
    }

    .c-burger__menu span {
        height: 2px;
        background-color: #000;
    }

    .c-burger__menu span:not(last-child) {
        margin-bottom: 4px;
    }

    .c-burger__menu {
        width: 18px;
    }

    .c-burger--collapse .c-burger__menu span:nth-child(1) {
        top: 6px;
    }

    .c-header__icon {
        margin-right: 0 !important;
    }

    .c-main-topics {
        padding: 12px !important;
    }

    .c-main-topics__container {
        padding: 0 !important;
    }

    .c-main-topics__main-article {
        max-width: 100% !important;

        img {
            width: 100%;
        }

        .c-article__excerpt {
            display: none;
        }
    }

    .c-article--main-topic {
        position: static !important;
        max-width: 100% !important;
        padding: 16px 0;
    }

    .c-main-topics__sub-article {
        border-top: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: -1px;
        padding: 24px 0;

        a:first-child {
            order: 2;
        }

        img {
            width: 120px;
            border-radius: 4px;
        }
    }

    .c-article--compact-topic {
        padding-left: 0;
    }

    .c-main-topics .c-break {
        display: none;
    }

    .c-main-topics__main-article,
    .c-post-list__content {
        .c-button--secondary {
            border-color: var(--color-border-1);
            background-color: var(--color-bg-1);
            color: var(--color-font-4);
        }
    }

    .c-article--main .c-article__title {
        line-height: 1.5em !important;
    }

    .c-main-topics__tags,
    .c-main-topics__tags .o-flex__item {
        margin-right: 0;
        padding-left: 0;
        margin-left: 0;
    }

    body.home {
        .o-layout--with-sidebar>.o-wrapper {
            padding: 0;
        }

        .o-layout__sidebar {
            padding: 24px 12px;
            margin-left: 0;

            .c-widget {
                padding-left: 0;
                margin-bottom: 6px;
            }
        }
    }

    .c-post-related__heading {
        text-align: left;
    }

    .c-post-related {
        padding-top: 0 !important;
    }

    .c-post-related__heading {
        padding: 0 12px;
    }

    .c-horizontal-nav {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: scroll;
        padding: 0 12px;

        .c-horizontal-nav__item {
            padding-right: 20px;
        }
    }

    .c-article--main,
    .c-article--normal {
        position: static;
        max-width: 100%;
        padding: 16px 0;

        .c-article__title {
            padding-left: 0;
        }

        .c-article__excerpt {
            display: none;
        }
    }

    body.home .c-post-list__item {
        img {
            width: 100%;
        }

        &:nth-child(n+4) {
            display: none;
        }
    }

    .c-post-list__item .c-article {
        box-shadow: none;
    }

    body.home {
        .c-article--event .c-article__desc {
            padding-right: 0 !important;
            padding-left: 115px !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .c-article__date-footer {
                padding: 3px !important;
                flex: 0 0 auto !important;
                margin-right: 0 !important;
                margin-left: 4px;
                font-size: 10px !important;
            }

            .c-article__date-small {
                flex: 0 0 auto !important;
            }

            .c-article__content {
                display: flex;
            }

            .c-article__meta {
                order: 19;
            }

            .c-article__title {
                padding-top: 4px;
                min-height: 88px;
                margin-bottom: -20px;
            }
        }
    }

    // .c-post-list{
    //     padding: 12px;
    // }
    .c-article--event .c-article__date-footer span,
    .c-article--event .c-article__date-footer strong {
        display: inline;
    }

    .c-article--calendar-small .c-article__date {
        width: 108px;
        height: 108px;
    }

    .o-layout--with-sidebar .o-layout__content .o-wrapper {
        padding: 0 12px;
    }

    .c-post-list__content .o-flex--grids {
        flex-wrap: wrap;
    }

    body.home .o-link.c-button {
        width: calc(100vw - 24px);
    }

    .c-header .c-header__search {
        width: 100vw;
        left: -60px;
        height: 100vh;
    }

    .c-header__mobile {
        width: 100vw;
        height: 100vh;
        top: 100px;
    }

    .c-hero {
        padding: 32px 0;
    }

    // .c-breadcrumbs{
    //     padding: 0 24px;
    // }
    .c-breadcrumbs .breadcrumb_last-link:before {
        display: none;
    }

    .c-breadcrumbs .breadcrumb_last-link {
        display: flex;
    }

    .c-post-list__content img {
        width: 100%;
    }

    .c-post-list--grid {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        &>div:not(:first-child) {
            display: flex;
            border-top: 1px solid #E6E6E6;
            border-bottom: 1px solid #E6E6E6;
            margin-bottom: -1px;
            padding: 24px 0;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            img {
                order: 2;
                width: 120px;
                max-height: 80px;
                height: auto;
                border-radius: 4px;
                flex: 0 0 120px;
                margin-left: 12px;
            }
        }
    }

    .fc .fc-toolbar {
        margin-left: 0;
        width: 100%;
        margin-bottom: 0;
    }

    .o-wrapper {
        padding: 0 12px;
    }

    .c-search-filters__mobile {
        font-size: 14px;
        text-align: right;
        padding: 24px 12px;
        margin-bottom: 12px;
        margin-top: -40px;
        margin-left: -12px;
        margin-right: -12px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

        .o-link {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            svg {
                margin-left: 6px;
            }
        }
    }

    .c-article--event {
        .c-article__desc {
            padding: 16px !important;

            .c-article__image {
                width: 100px !important;
                height: 100px !important;
                flex: 0 0 100px !important;
            }

            .c-article__title {
                min-height: 72px;

                &:first-child {
                    min-height: 108px;
                }
            }

            .c-article__excerpt {
                color: #737373;
                line-height: 1.5em;
            }

            .c-article__excerpt,
            .c-article__footer {
                margin-left: -114px;
                width: calc(100% + 114px);
                flex: calc(100% + 114px);
                flex-wrap: wrap;
            }

            .c-article__date-footer,
            .c-article__date_small {
                background: #fff !important;
                border-radius: 5px !important;
                color: #737373 !important;
                font-size: 12px !important;
                padding: 8px !important;
                border: 1px solid #E6E6E6 !important;
                line-height: 1em !important;
                display: flex !important;
                align-items: center !important;
                margin-right: 14px !important;
                height: auto !important;
                white-space: nowrap !important;
            }

            .c-article__date-footer {
                flex: auto !important;
                width: auto !important;
                flex-direction: row;
            }

            .c-article__place {
                color: #000;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }

    .u-footer__poland-img {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .c-footer__info {
        padding: 24px 12px;
        text-align: center;
    }

    .c-search-filters {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        padding: 24px 12px;
        overflow-y: scroll;

        .c-search-filters__meta span {
            display: none;
        }

        .c-search-filters__heading {
            font-size: 25px !important;
            font-weight: 400;
            font-family: $global-heading-font-family
        }

        .c-close-popup {
            top: 26px;
            right: 12px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        .c-filters__list,
        .c-filters__sort {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            display: block !important;
            width: 100%;

            .c-select__container,
            .c-button,
            .c-search-filters__group {
                width: 100%;
                text-align: left;
                margin-bottom: 12px;

                .c-select__container {
                    margin-bottom: 0;
                }
            }
        }

        .c-search-filters__meta {
            text-align: center;
            margin-top: 12px;
        }

        .c-label__container {
            width: 100%;
        }

        .o-flex {
            flex-wrap: wrap;
        }

        .o-flex--lock-top {
            flex-direction: column-reverse;
        }

        .js-allfilters {
            display: none;
        }
    }

    .post-type-archive-polish-companies .c-post-related__heading {
        display: none;
    }

    .post-type-archive-polish-companies .o-layout--with-sidebar>.o-wrapper {
        padding: 0;
    }

    blockquote[style] {
        padding: 60px 24px 24px;
    }

    .post-type-archive:not(.post-type-archive-polish-companies),
    body.search {
        .c-post-related__heading {
            background: #fff;
            margin-bottom: -4px;
            margin-left: -24px;
            margin-right: -24px;
            padding: 0 12px;
            width: calc(100% + 48px);
        }

        .c-search-filters__mobile {
            margin-top: 0;
            margin-left: -24px;
            margin-right: -24px;
        }
    }

    body.search {
        .c-hero__content {
            display: none
        }

        .c-search-results {
            padding-top: 0;
        }
    }
}