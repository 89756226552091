.c-quiz {
  display: none;

  &.is-active {
    display: block;
  }

  .c-select__list {
    position: static;
    display: none;

    &.is-open {
      display: block;
    }
  }
}
