.c-text-image {
  &__image {
    height: 300px;
    width: auto;
    position: relative;

    @include mq($from: large) {
      min-width: 50vw;
      min-height: 100%;
      height: auto;
      transform: translateX(0) !important;
    }

    @include mq($until: large) {
      left: 50%;
    }
  }

  &__text {
    @include mq($until: large) {
      order: 2;
    }
  }

  &__container {
    @include mq($until: large) {
      order: 1;
    }
  }
}
