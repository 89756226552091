/* stylelint-disable selector-class-pattern */
.wpml-ls-last-item {
  margin-left: 5px !important;
}

.wpml-ls-last-item a:before {
  content: '/';
  font-size: 10px;
  vertical-align: top;
  padding-top: 3px;
  display: inline-block;
  margin-right: 5px !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .wpml-ls-menu-item {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  .c-main-nav {
    text-align: center;
  }

  .wpml-ls-last-item a:before {
    color: #000 !important;
  }
}

.wpml-ls-menu-item a:after {
  display: none !important;
}

.wpml-ls-current-language a,
.wpml-ls-menu-item a:hover {
  color: #6deed8 !important;
}

.c-score__logo {
  top: 10px;
  right: 20px;
}

.c-score__desc {
  min-height: 5em;
}

.u-recapchta-info {
  padding: 15px 0;
  max-width: 300px;
  font-size: 0.6em !important;
  opacity: 0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-width-100 {
  width: 100%;
}

.u-hero--lightgrey {
  background-color: #e4e4e4 !important;
}

// .c-post__content a:not(.c-button) {
//   color: color-var(font, 4);
//   text-decoration: underline;
// }

.wp-block-image a[target='_blank']:not([class])::after {
  display: none;
}

.u-link--hover-color-inherit {
  &:hover {
    color: inherit;
  }
}

.single-branze .c-industries-section__column {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.green, .red {
  flex: 0 0 100%;
  font-size: 14px;
}

.green {
  color: green;
}

.red {
  color: red;
}

@include mq($from: 'large') {
  .term-invest-in-poland {
    .c-article {
      position: relative;
      padding-bottom: $inuit-global-spacing-unit-huge;

      &__title {
        min-height: $inuit-line-height-h3 * 2;
      }

      .c-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.u-footer__poland-img {
  margin: 0 auto $inuit-global-spacing-unit-great;
  display: block;
  @include mq($from: large) {
    margin: 0 0 0 $inuit-global-spacing-unit-massive;
  }
}

.u-list-type {
  &--lower-latin {
    list-style-type: lower-latin;
  }
}

.page-id-1240 .c-post-list__item {
  @include mq($until: large) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.u-image--mh-100 {
  max-height: 100px !important;
}

.post-type-archive-kierunki-eksportu .c-article__title {
  min-height: $inuit-global-spacing-unit-massive - $inuit-global-spacing-unit-tiny;
}

.term-przewodnik-eksportera {
  .c-article__content {
    padding-bottom: $inuit-global-spacing-unit-huge;

    .c-button {
      position: absolute;
      bottom: $inuit-global-spacing-unit-large;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .c-article__excerpt p {
    margin-bottom: 0;
  }
}

.u-text--underline {
  text-decoration: underline;
}


.u-width--1\/2 {
  width: calc(50% - 12px);
}

.u-width--full\@mobile {
  @include bp-down(medium) {
    width: 100%;
  }
}

.u-space {
  &--nowrap {
    white-space: nowrap;
  }
}

.u-overflow-visible {
  overflow: visible;
  height: auto;
}
