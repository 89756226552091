/* ==========================================================================
   #FAQ
   ========================================================================== */

.c-faq {
  &__item {
    border: 1px solid color-var(border);
    border-radius: $global-radius;
    padding: $inuit-global-spacing-unit-great;
    padding-right: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-big;
    margin-bottom: $inuit-global-spacing-unit-big;
    position: relative;

    &.is-active {
      .c-faq {
        &__description {
          display: block;
        }

        &__chevron {
          transform: rotateZ(180deg);
          top: $inuit-global-spacing-unit-great - 2px;
        }
      }
    }
  }

  &__title {
    font-weight: 700;
    margin-bottom: 0;
  }

  &__description {
    margin-top: $inuit-global-spacing-unit-great;
    margin-bottom: 0;
    display: none;
  }

  &__chevron {
    position: absolute;
    top: $inuit-global-spacing-unit-great + 2px;
    right: $inuit-global-spacing-unit-great;
  }

  &--categorized {
    &:not(.is-active) {
      display: none;
    }
  }
}
