@mixin darkMode() {
    --color-font-1: #ffffff;
    --color-font-2: #ffffff;
    --color-font-3: #000000;
    --color-font-4: #ffff00;
    --color-font-5: #ffff00;
    --color-font-6: #34B53A;
    --color-font-7: #DC143C;
    --color-bg-1: #121212;
    --color-bg-2: #ffff00;
    --color-bg-3: #ffff00;
    --color-bg-4: #525960;
    --color-bg-5: #121212;
    --color-bg-6: #34B53A;
    --color-bg-7: #DC143C;
    --color-bg-8: #000000;
    --color-border-1: #ffff00;
    --color-border-2: #ffff00;
    --color-border-3: #ffff00;
    --color-border-4: #ffffff;
    --color-border-5: #525960;
    --color-border-6: #000000;
    --color-border-7: #000000;
    --color-border-8: #34B53A;
    --color-border-9: #DC143C;
}
