/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
    display: flex;
    align-items: center;
    margin: 0;

    &--right {
        justify-content: flex-end;
    }

    &--mobile {
        display: block;
    }
}

.c-main-nav__item {
    list-style: none;
    margin: 0;
    @include inuit-font-size($inuit-font-size-h6);

    &:not(:first-child) {
        margin-left: $inuit-global-spacing-unit-large;
    }

    &:hover .c-main-nav__dropdown {
        display: flex;
    }

    &:hover,
    &.current-menu-item,
    &.current-page-ancestor {
        border-bottom-color: color-var(border, 3) !important;

        .c-main-nav__link {
            font-weight: 700;
        }
    }

    .c-header__mobile-nav & {
        margin-left: 0;
        display: block;
        border: none;
        text-align: left;

        &:hover {
            .c-main-nav__link {
                font-weight: 400;
            }
        }

        &:not(:last-child) {
            margin-bottom: $inuit-global-spacing-unit-big;
        }
    }
}

.c-main-nav__link {
    display: block;
    // padding: ($inuit-global-spacing-unit-great - 2px) 0;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
        color: inherit;
    }

    .c-header__mobile-nav & {
        display: inline-flex;
        padding: 0;
    }
}

.c-main-nav__dropdown {
    display: none;
    flex-direction: column;
    margin: 0;
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;
    width: 100%;
    background: color-var(bg, 5);
    padding: $inuit-global-spacing-unit-large 0;

    &:before {
        @include absolutePseudoContent();
        bottom: 100%;
        left: 0;
        width: 100%;
        height: $inuit-global-spacing-unit-large;
    }
}

.c-main-nav__list,
.c-main-nav__subitem {
    list-style: none;
    margin: 0;
}

.c-main-nav__sublink {
    display: block;
    padding: 0.5em;
    text-decoration: none;
    color: color-var(font);

    &:hover {
        color: color-var(font, 4);
    }
}

.current-menu-item .c-main-nav__sublink {
    border-bottom-color: color-var(border);
}