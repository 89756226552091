/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-checkbox {
  @include clickableInput();

  &__replacement {
    border-radius: 0;
    @include checkboxTick();

    &.is-halfly-completed {

      &:before,
      &:after {
        width: 14px !important;
        height: 14px !important;
        top: 4px !important;
        left: 4px !important;
        transform: none;
        background: color-var(bg, 2);
      }
    }
  }
}
