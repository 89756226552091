.c-link-box {
  display: flex;
  align-items: center;
  border: 1px solid #E6E6E6;
  border-radius: $global-radius;
  // padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
  padding: $inuit-global-spacing-unit !important;
  margin-bottom: $inuit-global-spacing-unit-big;
  height: calc(100% - #{$inuit-global-spacing-unit-big});
  // box-shadow: $global-shadow;


  @include bp('medium') {
    height: calc(100% - #{$inuit-global-spacing-unit-great});
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
    margin-bottom: $inuit-global-spacing-unit-great;
    border: 2px solid color-var(border);
  }

  &__title {
    flex: 1;
    padding-left: $inuit-global-spacing-unit-great;
    padding-right: $inuit-global-spacing-unit-great;
    margin-bottom: 0;
    // @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h6);
    font-size: 14px !important;
    color: color-var(font, 4);
    font-weight: 500;

    @include bp('medium') {
      @include inuit-font-size($inuit-global-font-size,
        $inuit-global-line-height );
    }

    .avalibility-message {
      display: block;
      font-size: 14px;

      &.is-ok {
        color: color-var('success');
      }

      &.is-waiting {
        color: #ff7b00;
      }

      &.is-no {
        color: color-var('error');
      }
    }
  }

  &__image {
    margin-bottom: 0 !important;
  }

  svg {
    width: $inuit-global-spacing-unit-large;
    height: $inuit-global-spacing-unit-large;
  }

  .c-link-box__image {
    filter: brightness(0) saturate(100%) invert(21%) sepia(86%) saturate(2620%) hue-rotate(196deg) brightness(94%) contrast(101%);
  }

  &:hover {
    border: 1px solid color-var(border, 2);

    @include bp('medium') {
      border: 2px solid color-var(border, 2);
    }

    .c-link-box__image {
      filter: brightness(0) saturate(100%) invert(20%) sepia(83%) saturate(7272%) hue-rotate(342deg) brightness(89%) contrast(92%);
    }
  }

  &--small {
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
    box-shadow: none;

    @include bp-down('medium') {
      box-shadow: $global-shadow-strong;
    }

    .c-link-box {
      &__title {
        padding-left: $inuit-global-spacing-unit-big;
        padding-right: $inuit-global-spacing-unit-small;
        @include inuit-font-size($inuit-font-size-h5);

        @include bp-down('medium') {
          @include inuit-font-size($inuit-font-size-h5, $inuit-line-height-h6);
        }
      }
    }
  }

  &--chevron-left {
    flex-direction: row-reverse;

    svg {
      transform: rotate(180deg);
    }
  }
}