@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.c-toast {
  $this: &;

  opacity: 0;
  position: absolute;
  top: 270px;
  left: auto;
  right: 2rem;
  margin: auto;
  background: white;
  padding: 16px;
  padding-right: $inuit-global-spacing-unit-massive;
  border-radius: 4px;
  z-index: 999999999;
  border: 1px solid color-var(func, 2);
  color: color-var(font, 1);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  width: 800px;
  max-width: calc(100% - #{$inuit-global-spacing-unit-huge});
  animation: fadeInUp 1s forwards;

  @include bp(xlarge) {
    right: calc((100vw - 1200px) / 2);
  }

  svg {
    pointer-events: none;
    width: 24px;
    height: 24px;

    polyline {
      stroke-width: 5 !important;
    }
  }

  @include bp-down("large") {
    left: 0;
    right: 0;
    width: calc(100% - #{$inuit-global-spacing-unit-huge});
    padding-right: $inuit-global-spacing-unit-huge;
    margin: auto;
    top: 90px;
  }

  &--success {
    border: 1px solid color-var(success, 1);
    background: #eefff4;
  }

  &--error {
    border: 1px solid color-var(error, 1);
    background: #fff4f4;
  }

  &__clear {
    position: absolute;
    top: 0;
    right: $inuit-global-spacing-unit-large;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    transition: $global-transition;
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;
      transition: $global-transition;
      opacity: 0.7;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}
