/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  @include inuit-font-size($inuit-font-size-h5);
  padding-bottom: $inuit-global-spacing-unit-great;
  background: color-var(bg);
  position: relative;
  z-index: 0;
  filter: none !important;

  @include bp('medium') {
    padding-bottom: $inuit-global-spacing-unit-massive;
  }

  @include bp-down('medium') {
    &__ue {
      flex-direction: column;
      text-align: center;
    }

    .o-flex--auto,
    .o-flex--grids {
      margin-left: 0;
    }

    .o-flex--auto>.o-flex__item,
    .o-flex--grids>.o-flex__item {
      padding-left: 0;
    }
  }

  &__top {
    color: color-var(font, 3);
    background: color-var(bg, 3);
    padding: 1.5 * $inuit-global-spacing-unit-big 0;
    position: relative;

    @include bp-down('medium') {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
    }

    @include bp('medium') {
      padding: $inuit-global-spacing-unit-small;

      &:before {
        content: '';
        top: 0;
        right: calc(100% - 24px);
        width: 50vw;
        height: 100%;
        position: absolute;
        background: color-var(bg);
        z-index: 100;
      }
    }

    &:after {
      content: '';
      top: 0;
      left: -$inuit-global-spacing-unit-big;
      height: 100%;
      right: -$inuit-global-spacing-unit-big;
      position: absolute;
      background: color-var(bg, 3);
      z-index: 1;

      @include bp('medium') {
        left: 100%;
        width: 50vw;
        right: auto;
      }
    }

    @include bp-down('medium') {
      &-logo-text {
        display: block;

        .c-heading--h0 {
          @include inuit-font-size($inuit-font-size-h1);
        }

        .c-footer__top-logo-text-paragraph {
          @include inuit-font-size($inuit-font-size-h6, $important: true);
        }
      }

      &-coat-of-arms {
        order: 2;
        z-index: 2;
        position: relative;
      }

      &-link {
        order: 1;
        margin-bottom: 1.5 * $inuit-global-spacing-unit-big;
        @include inuit-font-size($inuit-font-size-h6, $important: true);
      }
    }

    @include bp-down('medium') {
      .c-footer__top-link {
        z-index: 2;
        margin-left: auto;
        margin-right: auto !important;
        position: relative;
      }
    }
  }

  &__logo {
    max-width: 47px;
    filter: none !important;

    @include bp('medium') {
      max-width: 75px;
      margin-left: $inuit-global-spacing-unit-large;
    }
  }

  &__address {
    // padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-big 0;
    padding: $inuit-global-spacing-unit-large 0 0;

    @include bp-down('medium') {
      text-align: center;
    }

    @include bp('large') {
      // padding: 1.5 * $inuit-global-spacing-unit-huge
      //   $inuit-global-spacing-unit-massive 0;
      padding: $inuit-global-spacing-unit-large 0 0;
    }
  }

  &__nav {
    @include mq($from: medium) {
      padding: 1.5 * $inuit-global-spacing-unit-huge 1.5 * $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-big;
      padding-left: 1.5 * $inuit-global-spacing-unit-huge !important;
      background: color-var(bg, 5);
      position: relative;

      &:before {
        content: '';
        top: 0;
        left: 100%;
        width: 50vw;
        height: 100%;
        position: absolute;
        background: color-var(bg, 5);
      }
    }
  }

  &__nav-widget {
    @include mq($until: medium) {
      display: none;
    }
  }

  &__mobile-nav {
    text-align: center;
    @include inuit-font-size($inuit-font-size-h6);
    margin-bottom: 1.5 * $inuit-global-spacing-unit-large;

    @include bp('medium') {
      display: none;
    }
  }

  &__social {
    display: block;
    margin-top: 1.5 * $inuit-global-spacing-unit-large;

    @include bp('medium') {
      margin-top: $inuit-global-spacing-unit-huge;
    }
  }

  &__horizontal-nav {
    @include mq($from: medium) {
      margin-top: $inuit-global-spacing-unit-huge;
    }

    .menu {
      display: flex;

      @include mq($until: medium) {
        flex-direction: column;
        align-items: center;
      }

      &-item {
        @include mq($from: medium) {
          margin-right: $inuit-global-spacing-unit-big;
        }
      }
    }
  }

  &__bottom {
    padding-left: 0 !important;
  }

  &__info {
    color: color-var(font, 2);
    line-height: $inuit-font-size-h4;
    margin-bottom: $inuit-global-spacing-unit-great;
    padding: 32px 32px 0 0;

    @include bp('medium') {
      margin-bottom: $inuit-global-spacing-unit-huge;
    }
  }

  @include bp-between('medium', 'large') {
    &__ue {
      .o-flex__item:not(last-child) {
        margin-right: $inuit-global-spacing-unit-great;
      }
    }
  }
}