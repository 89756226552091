/* ==========================================================================
   #LINK
   ========================================================================== */

.o-link {
  color: color-var(font, 4);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: color-var(font, 5);
  }

  &--smooth {
    text-decoration: underline;
    color: inherit;
    font-weight: 500;

    &:hover {
      color: inherit;
      opacity: 0.8;
    }
  }

  &--visible {
    text-decoration: underline;
    color: color-var(font, 4);

    &:hover {
      color: color-var(font, 5);
    }
  }

  &--hidden {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: color-var(font, 4);
    }
  }

  &--svg {
    margin-right: $inuit-global-spacing-unit-tiny;

    &:hover {
      path {
        fill: color-var(font, 4);
      }

      circle {
        stroke: color-var(font, 4);
      }
    }
  }

  &--with-icon {
    position: relative;
    padding-right: $inuit-global-spacing-unit-large;
    text-decoration: none;

    .c-icon {
      left: auto;
      right: $inuit-global-spacing-unit-micro;

      svg {
        width: 0.75em;
        height: 0.75em;

        * {
          fill: color-var(font, 4);
        }
      }
    }
  }

  &--with-icon-right {
    position: relative;
    padding-left: $inuit-global-spacing-unit-large;
    margin-left: -$inuit-global-spacing-unit-large;

    .c-icon {
      right: auto;
      left: -$inuit-global-spacing-unit-micro;

      svg {
        width: 0.75em;
        height: 0.75em;
      }
    }
  }

  &[target='_blank'] {
    padding-right: $inuit-global-spacing-unit-large;
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      width: $inuit-global-spacing-unit;
      height: $inuit-global-spacing-unit;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $inuit-global-spacing-unit-micro;
      margin: auto;
      background: url('../assets/images/svg/noweokno.svg');
    }
  }

  &--bigger {
    font-size: 1.25em;
    line-height: 1.6em;
  }

  &--white {
    color: color-var(font, 3);
  }

  &--svg--white {
    svg {
      * {
        fill: color-var(font, 3);
      }
    }
  }

  &--pointer-events-all {
    pointer-events: all;
  }
}
