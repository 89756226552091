.fc-dayGridWeek-view td.fc-daygrid-day {
  padding-top: 50px !important;
  position: relative;

  &:before {
    position: absolute;
    top: $inuit-global-spacing-unit-small;
    right: $inuit-global-spacing-unit;
    content: attr(data-date);
    @include inuit-font-size($inuit-font-size-h1, 1em);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    width: $inuit-font-size-h1 + 2px;
    text-align: right;
    direction: rtl
  }
}

.fc-daygrid-day-number {
  @include inuit-font-size($inuit-font-size-h1, 1em);
  font-weight: 400;
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit !important;
}

.fc .fc-daygrid-day-frame {
  min-height: 200px !important;
}

.fc-col-header {
  margin: 0;
}

.fc-button {
  @extend .c-button;
}

.fc-toolbar-title {
  @include inuit-font-size($inuit-font-size-h1, 1.1em, 0, true);
  font-weight: 400;
  @include mq($until: medium) {
    margin-bottom: $inuit-global-spacing-unit-big !important;
  }

  &:first-letter {
    text-transform: uppercase;
  }
}

.fc-col-header-cell {
  @include inuit-font-size($inuit-font-size-h6, 1.1em, 0, true);
  font-weight: 400;
  color: color-var(font, 2);
  text-align: right !important;
  direction: rtl;

  &-cushion {
    padding: $inuit-global-spacing-unit 0 !important;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.fc-scrollgrid-sync-table {
  margin: 0;
  min-height: 600px;
}


.fc-theme-standard table,
.fc-theme-standard th {
  border: 0 !important;
}

.fc .fc-scrollgrid-section>td {
  border: 0 !important;
}

.fc-theme-standard tbody td {
  border: 1px solid #979797 !important;
}

.fc-header-toolbar {
  border-radius: 10px;
  background: color-var(bg, 5);
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit;
  min-height: 105px;

  // .fc-toolbar-chunk {
  //   min-width: 150px;
  // }
  .c-input{
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    text-align: center;
    margin: 4px 0;
    cursor: pointer;
    padding: 10px 32px;
    transition: all 0ms linear 0ms;
    font-size: 16px;
    font-size: 0.88889rem;
    line-height: 1.136em;
    text-decoration: none;
    border: 1px solid var(--color-border-1);
    border-radius: 5px;
    color: var(--color-font-3);
    position: relative;
    margin: 0 !important;
    font-size: 0.77778rem;
    -webkit-appearance: button;
    background-color: transparent;
    padding: 10px 16px;
    border-color: var(--color-border-2);
    background-color: var(--color-bg-1);
    color: var(--color-font-4);
    &::placeholder{      
      color: var(--color-font-4);
    }
  }
}

.fc .fc-button.fc-button-primary {
  border-color: color-var(border, 5);
  background: color-var(bg);
  color: color-var(font);
  @include inuit-font-size($inuit-font-size-h5);

  &.fc-prev-button,
  &.fc-next-button {
    padding: 6px !important
  }

  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    border-color: color-var(border, 5);
    background: color-var(bg);
    color: color-var(font);
  }

  &.fc-miniCal-button {
    border: 0;
    background: none;
    padding: 0;
    -webkit-user-select: none;
    user-select: none;
    font-family: fcicons !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    @include inuit-font-size($inuit-font-size-h5);

    &:before {
      content: "\e901";
      display: block;
      position: static;
      background: none;
      transform: rotate(90deg);
      opacity: 1;
      transition: none;
      pointer-events: all;
      font-size: 1.5em;
      color: color-var(font);
    }

  }
}

.fc-toolbar-chunk:nth-child(2)>div {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@include mq($until: medium) {
  .fc-header-toolbar {
    justify-content: center;
    flex-wrap: wrap;

    .fc-toolbar-title {
      @include inuit-font-size($inuit-font-size-h2, (32/20),0, true);
      font-weight: bold;
    }
  }

  .fc-toolbar-chunk {
    width: 100%;

    &:nth-child(1) {
      display: flex;
      justify-content: center;
    }

    &:nth-child(3) {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 30px;
      padding: 0 $inuit-global-spacing-unit-great;
    }
  }

  .fc .fc-list-day-cushion  {
    display: flex;
    justify-content: center;
    color: color_var(sub, 2);
    font-size: 14px;
    padding: $inuit-global-spacing-unit !important;
    margin-bottom: $inuit-global-spacing-unit;
  }

  .fc-list-event-title, .fc-theme-standard tbody td {
    padding: 0 !important;
    border: none !important;
  }

  .fc-list-event .c-event {
    margin: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit;
    border: 1px solid #E1E1E1;
  }

  .fc-theme-standard .fc-list {
    border: none !important;
  }
}

.fc .fc-event-title {
  font-weight: 500;
}

.fc .c-label {
  margin-bottom: 0;
  margin-right: $inuit-global-spacing-unit-small;
}

.fc {

  &.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
  &.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
    border-top-right-radius: 35px 20px;
    border-bottom-right-radius: 35px 20px;
    border-right-width: 1px;
    padding-right: 20px;
  }

  &.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
  &.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
    border-top-left-radius: 35px 20px;
    border-bottom-left-radius: 35px 20px;
    border-left-width: 1px;
    padding-left: 20px;
  }
}

.fc .fc-list-event-time,
.fc .fc-list-event-graphic {
  display: none;
}

.fc .fc-daygrid-event {
  margin: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-tiny !important;
}

.fc-scroller-harness {
  overflow: visible !important;
}

.fc .fc-daygrid-event-harness-abs {
  // z-index: 1;
}

.fc .fc-toolbar {
  @include mq($until: medium) {
    flex-direction: column-reverse;
    padding: $inuit-global-spacing-unit-great;
    width: 100vw;
    margin-left: - $inuit-global-spacing-unit-big;
    border-radius: 0;
    position: relative;

    .c-input__container {
      position: static;
    }
  }
}

.fc-today-button {
  border-color: color-var(border, 3) !important;
}

.fc-event-main-frame {
  padding: $inuit-global-spacing-unit-tiny * 0.75 $inuit-global-spacing-unit-tiny;
}

@include mq($until: medium) {
  .fc-theme-standard .fc-list {
    margin-left: -$inuit-global-spacing-unit-big;
    margin-right: -$inuit-global-spacing-unit-big;
  }

  .fc-list-day-side-text {
    display: none;
  }
}

@include mq($from: medium) {
  .fc-daygrid-event:hover {
    background-color: color_var(bg, 2) !important;

    .fc-event-title.fc-sticky {
      color: color_var(font, 3);
    }
  }
}

.fc .fc-daygrid-event {
  z-index: auto !important;

  &:hover .fc-event-main {
    z-index: 10;
  }
}

.c-event__popup .c-article--event {
  border: none;
}