.c-industries-section {
	margin: $inuit-global-spacing-unit-great 0 ;
	&__item {
		position: relative;
		margin-bottom: $inuit-global-spacing-unit-small;
		border-radius: 5px;
		border: 1px solid #868585;
		&-link {
			color: color-var(font,1);
			letter-spacing: 0;
			@include inuit-font-size($inuit-font-size-h5,$inuit-line-height-h5);
			padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit;
		}
		&-button {
			position: absolute ;
			padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big  ;
			top: 0 ;
			right: 0 ;
			margin: auto ;
			border: none ;
			box-shadow: none ;
			background: none ;
			pointer-events: none ;
			cursor: pointer;
			z-index: 1;
			&.has-child {
				pointer-events: auto;
				.c-icon {
					transform: rotate(0deg);
					&--rotate-180 {
						transform: rotate(-180deg);
					}
				}
			}
			.c-icon {
				width: 100%;
				left: 0;
				position: static;
				transform: rotate(-90deg);
			}
			svg {
				width: 100%;
				height: auto;
			}
		}
	}
	&__list-second {
		display: none;
		padding: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great ;
		&.is-active {
			display: flex;
		}
		&-item {
			margin-bottom: $inuit-global-spacing-unit-tiny;
		}
		&-link {
			display: block;
			padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-large $inuit-global-spacing-unit-micro 0 ;
		}
	}
}
